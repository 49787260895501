<template>
  <div class="centered-wrapper">
    <div class="text-wrapper">
      <AdvancedLink
          :link="link">
        {{text}}
      </AdvancedLink>
    </div>
  </div>

</template>

<script>
  import AdvancedLink from "./AdvancedLink";
  export default {
    name: 'Link',
    props: ['text', 'link'],
    components:{
      AdvancedLink
    }
  };
</script>