<template>
  <footer>
    <div class="section">
      <Newsletter :size="'sm'"/>
    </div>
    <div class="section info">
      <div class="info">
        <div class="info-section">
          <div class="separator"></div>
          <div class="content">
            <p>Best of Swiss Web GmbH</p>
            <p>c/o Netzmedien AG</p>
            <p>Heinrichstr. 235</p>
            <p>8005 Zurich</p>
            <p>{{$t('footer.telephone')}} +41 44 355 63 63</p>
          </div>
        </div>

        <div class="info-section">
          <div class="separator"></div>
          <div class="content">
            <p>Support</p>
            <b-link
                :href="'mailto:' + $t('footer.' + context + '.support_email')">
              {{$t('footer.' + context + '.support_email')}}
            </b-link>
          </div>
        </div>

        <div class="info-section">
          <div class="separator"></div>
          <div class="content">
            <p>{{$t('footer.media')}}</p>
            <b-link
                :href="'mailto:' + $t('footer.' + context + '.press_email')">
              {{$t('footer.' + context + '.press_email')}}
            </b-link>
            <p>Martin J. Matt</p>
            <p>+41 61 312 45 81</p>
          </div>
        </div>

        <div class="info-section">
          <div class="separator"></div>
          <div class="content">
            <AdvancedLink v-if="link_1" :link="link_1.destination">{{link_1.text}}</AdvancedLink>
            <AdvancedLink v-if="link_2" :link="link_2.destination">{{link_2.text}}</AdvancedLink>
            <AdvancedLink v-if="link_3" :link="link_3.destination">{{link_3.text}}</AdvancedLink>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
  import { updateViewKey } from '../helpers/AppHelpers';
  import { BLink } from 'bootstrap-vue';
  import Newsletter from '../components/Newsletter';
  import AdvancedLink from "./AdvancedLink";

  export default {
    name: 'Footer',
    props: ['links'],
    components: {
      AdvancedLink,
      'b-link': BLink,
      Newsletter
    },
    data(){
      return {
        context : 'bosw'
      }
    },
    computed:{
      link_1(){
        return this.links.link_1;
      },
      link_2(){
        return this.links.link_2;
      },
      link_3(){
        return this.links.link_3;
      }
    },
    mounted(){
      if(document.querySelector('#app').classList.contains('bosa'))
        this.context = 'bosa';
      else if(document.querySelector('#app').classList.contains('boss'))
        this.context = 'boss';
    },
    methods:{
      updateViewKey: function(){
        updateViewKey();
      }
    }
  };
</script>
<style scoped lang="scss">
  footer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    background-color: $black;
    color: $footer-color;
    padding:$spacer/2 $spacer;
    padding-left: 0;
    margin-top:$spacer;
    font-style:italic;
    font-size:$smaller-font-size;
    font-weight:300;

    .section{
      padding-bottom:$spacer/2;

      .info{
        display:flex;
        flex-direction:row;
        flex-wrap:wrap;

        .info-section{
          display:flex;
          flex-direction:row;
          margin: 0;
          padding-top:$spacer;
          padding-left:$spacer;

          .separator{
            align-self:center;
            height:calc(100% - 0.4rem);
            width:1px;
            background-color:$separator-color;
            margin-right:$spacer/2;
            margin-top:1px;
          }

          .content{
            display:flex;
            flex-direction:column;
            align-items:flex-start;

            p{
              margin-bottom:$spacer/4;
              line-height:1;

              &:last-child{
                padding-bottom:0;
                margin-bottom:0;
              }
            }

            a {
              line-height:1;
              padding-bottom:3px;
              margin-bottom:$spacer/8;
              transition:$btn-transition;

              &:hover{
                color: $footer-color-lighter !important;
              }
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-down(md){
    footer{
      font-size: $smaller-font-size * 0.8;
    }
  }
</style>