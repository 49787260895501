<template>
  <span :id="formattedAnchor"></span>
</template>

<script>
  import {formatAnchor} from "../helpers/AppHelpers";

  export default {
    name: 'Anchor',
    props: ['anchor'],
    computed:{
      formattedAnchor(){
        return formatAnchor(this.anchor);
      }
    }
  }
</script>