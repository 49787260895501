<template>
  <div>
    <div class="partners-bar" v-for="(collection, index) in splitCollection" :key="index">
      <PartnersBarItem
          v-for="entry in collection"
          :key="entry['@id']"
          :partner="entry.partner"/>
    </div>
  </div>
</template>

<script>
import PartnersBarItem from "./PartnersBarItem";
export default {
  name: 'PartnersBar',
  components: {PartnersBarItem},
  props: ['partners'],
  data(){
    return{
      windowWidth: window.innerWidth
    }
  },
  computed:{
    isMobile(){
      return this.windowWidth <= 576;
    },
    splitCollection(){
      // Use two rows on small screens
      let collection = [];

      if(this.isMobile){
        const half = Math.ceil(this.partners.length / 2);
        collection = [this.partners.slice().splice(0, half), this.partners.slice().splice(-half)];
      }
      else{
        collection = [this.partners];
      }

      return collection;
    }
  },
  mounted(){
    window.addEventListener('resize', () => this.windowWidth = window.innerWidth);
  }
};
</script>

<style scoped lang="scss">
  .partners-bar{
    display:flex;
    flex-direction:row;
    justify-content:space-evenly;
    align-items:center;
    max-width:100vw;
  }
</style>