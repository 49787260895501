<template>
  <div class="centered-wrapper component-margin">
    <div class="projects-wall-wrapper" :class="context">
        <ProjectsWallItem
            v-for="(entry, index) in sortedCollection"
            :key="index"
            :entry="entry"/>
    </div>
  </div>
</template>

<script>
  import ProjectsWallItem from "./ProjectsWallItem";

  export default {
    name: 'ProjectsWall',
    components: {
      ProjectsWallItem
    },
    props: ['collection'],
    data(){
      return{
        context: 'bosw'
      }
    },
    async mounted(){
      if(document.querySelector('#app').classList.contains('bosa')){
        this.context = 'bosa'
      }
    },
    computed:{
      sortedCollection(){
        // Sort collection by project name
        let sortedCollection = [...this.collection];
        return sortedCollection.sort(function(a, b) {
          const nameA = a.project.toLowerCase();
          const nameB = b.project.toLowerCase();

          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
      }
    },
    methods:{

    }
  };
</script>

<style scoped lang="scss">
  .projects-wall-wrapper{
    position:relative;
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    width:100%;
    max-width:1040px;
  }

  .bosw{
    .projects-wall-wrapper{
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }

 @include media-breakpoint-down(md) {
   .projects-wall-wrapper{
     max-width:600px;
   }

   .bosw{
     .projects-wall-wrapper{
       grid-template-columns: repeat(3, minmax(0, 1fr));
     }
   }
 }

 @include media-breakpoint-down(sm) {
   .projects-wall-wrapper{
     grid-template-columns: repeat(3, minmax(0, 1fr));
   }

   .bosw{
     .projects-wall-wrapper{
       margin:0 $spacer/2;
       grid-template-columns: repeat(2, minmax(0, 1fr));
     }
   }
 }
</style>