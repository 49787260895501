export default {
  newsletter: {
    subscribe: 'Newsletter abonnieren',
    form_title: 'Ich melde mich zum newsletter an',
    name: 'Vorname',
    surname: 'Nachname',
    accept_data: 'Ich akzeptiere die DatenSchutz-Bestimmungen',
    submission_success: 'Glückwunsch sie wurden abonniert!',
    submission_error: 'Entschuldigung, etwas ist schief gelaufen.',
    already_subscribed: 'Diese E-Mail Adresse wurde bereits abonniert.',
    validation: {
      required: 'Dieses Feld ist erforderlich',
      email: 'Bitte verwenden Sie eine gültige E-Mail Adresse.',
      accept_data: 'Sie müssen die Datenschutzrichtlinie akzeptieren, um sich anzumelden'
    },
    bosw:{
      extra_subscription_1: 'Ich melde mich auch für den Best of Swiss Apps Newsletter an',
      extra_subscription_2: 'Ich melde mich auch für den Best of Swiss Software Newsletter an'
    },
    bosa:{
      extra_subscription_1: 'Ich melde mich auch für den Best of Swiss Web Newsletter an',
      extra_subscription_2: 'Ich melde mich auch für den Best of Swiss Software Newsletter an'
    },
    boss:{
      extra_subscription_1: 'Ich melde mich auch für den Best of Swiss Web Newsletter an',
      extra_subscription_2: 'Ich melde mich auch für den Best of Swiss Apps Newsletter an'
    }
  },
  footer: {
    telephone: 'Telefon',
    media: 'Medienkontakt',
    bosw: {
      support_email: 'support@bestofswissweb.ch',
      press_email: 'presse@bestofswissweb.ch'
    },
    bosa: {
      support_email: 'support@bestofswissapps.ch',
      press_email: 'presse@bestofswissapps.ch'
    },
    boss: {
      support_email: 'support@bestofswisssoftware.ch',
      press_email: 'presse@bestofswissapps.ch'
    }
  },
  timeline: {
    months: ['JAN', 'FEB', 'MRZ', 'APR', 'MAI', 'JUN', 'JUL', 'AUG', 'SEP', 'OKT', 'NOV', 'DEZ']
  },
  filter:{
    all_years: 'Alle Auszeichnungen',
    all_categories: 'Alle Kategorien',
    all_awards: 'Alle Auszeichnungen',
    gold: 'Gold',
    silber: 'Silber',
    bronze: 'Bronze',
    shortlist: 'Shortlist',
    master: 'Master'
  },
  client: 'Auftraggeber',
  contractor: 'Auftragnehmer',
  cookieConsent: 'Um unsere Webseite für Sie optimal zu gestalten und fortlaufend verbessern zu können, verwenden wir Cookies. Durch die weitere Nutzung der Webseite stimmen Sie der Verwendung von Cookies zu.'
}