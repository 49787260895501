<template>
    <main>
      <EditableArea v-if="main" v-bind:content="main" id="mainArea"/>
    </main>
</template>

<script>
import { EditableArea } from '../vue-editor';
import {trackPageView} from "../helpers/AppHelpers";

export default {
  name: 'Child',
  components: {
    EditableArea
  },
  props: ['title', 'metadata', 'main', 'meta'],
  created(){
    // Change the page title on navigation with the one specified on the meta_title dialog property
    if(this.meta?.meta_title)
      document.title = this.meta?.meta_title

    // GA Analytics tracking
    trackPageView(this, document.title);

  }
};
</script>