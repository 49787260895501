<template>
  <div id="app" :class="theme">
    <header>
      <Navigation
          :topNavigation="topNavigation"
          :hamburgerNavigation="hamburgerNavigation"
          :detailMode="detailMode"
          :isHomePage="isHomePage"
      />
    </header>

    <div class="Basic">
      <router-view :key="viewKey" v-if="theme && homePage"></router-view>
    </div>

    <Footer v-if="theme" :links="footer"/>

    <cookie-consent>
      <template slot="message">
        {{$t('cookieConsent')}}
      </template>

      <template slot="button">
        <button class="btn btn-info">OK</button>
      </template>
    </cookie-consent>

  </div>
</template>

<script>
import Vue from "vue";
import cookieconsent from 'vue-cookieconsent-component';
import {
  checkIfHomePage,
  getBasename,
  getRestResource,
  getRootComponents} from "./helpers/AppHelpers";
import {getCurrentLanguage, getLanguages} from "./i18n/i18n";
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import {setOptions, bootstrap }from "vue-gtag";
import postscribe from "postscribe";

export default {
  name: 'app',
  components: {
    Navigation,
    Footer,
    'cookie-consent': cookieconsent
  },
  data() {
    return {
      theme: '',
      homePage: '',
      topNavigation: [],
      hamburgerNavigation: [],
      rootcomponents: {},
      openLayers: 0,
      detailMode: false,
      viewKey: 1, // Used to force a page to remount even if navigating to the same route
      comingFromDifferentRoute: false, // Used to be able to programmatically close the sidebar only if coming from the same route
      measurementId: '',
      stagingMeasurementId: '',
      isHomePage: checkIfHomePage(),
      footer: {}
    };
  },
  watch:{
    openLayers(val){
      if(val)
        this.detailMode = true;
      else
        this.detailMode = false;
    }
  },
  async mounted() {
    // Set the page theme based on the basename
    // The v-if on the template ensures the components are not rendered before we have a basename
    // Clear the sessionStorage first
    sessionStorage.basename = '';
    let basename = await getBasename();
    switch(basename){
      case '/bestofswissweb':
        this.theme = 'bosw';
        break;
      case '/bestofswissapps':
        this.theme = 'bosa';
        break;
      case '/bestofswisssoftware':
        this.theme = 'boss';
        break;
      default:
        this.theme = 'bosw';
        break;
    }

    localStorage.isTrackingActive = false;

    // Set the html document lang attribute to the current language
    const currentLanguage = getCurrentLanguage();
    if(currentLanguage)
      document.documentElement.setAttribute('lang', currentLanguage);

    const pagenav = await getRestResource('pagenav', '');

    if (pagenav === undefined)
      return;

    // Assign each page to the correct navigation menu based on the values specified
    // by the user on the page dialog
    let topNavigation = [];
    let measurementId = '';
    let stagingMeasurementId = '';
    let addNexusScript = '';

    // Gets the name of the homepage from the first node of the tree and uses it throughout the application.
    // The v-if on the template ensures the page does not get loaded until we have a home page name.
    this.homePage = pagenav['@nodes'][0];
    Vue.prototype.$homePage = this.homePage;

    if (pagenav['recaptcha'])
      Vue.prototype.$recaptchaSiteKey = pagenav['recaptcha']

    if (pagenav['measurementId'])
      measurementId = pagenav['measurementId']

    if (pagenav['stagingMeasurementId'])
      stagingMeasurementId = pagenav['stagingMeasurementId']

    if (pagenav['addNexusScript']){
      addNexusScript = pagenav['addNexusScript']
      postscribe(document.body, addNexusScript);
    }

    // Recursively get the pages that need to show up on the Hamburger Menu
    this.getHamburgerNavigation(pagenav);

    // Get the top navigation items
    let rootComponents = await getRootComponents();
    let topNavigationButtons = rootComponents.topNavigationButtons;

    if(topNavigationButtons){
      for(let nodeName of topNavigationButtons['@nodes']){
        let node = topNavigationButtons[nodeName];

        if(node.link_external){
          node.linkPath = node.link_external;
        } else {

          // Get the page name from destination id, and use it as :to value
          let params = `?@jcr:uuid=${node.link}`;
          let query = process.env.VUE_APP_HOST + process.env.VUE_APP_REST_PAGENAV + params;

          const pagenavResponse = await fetch(query);

          const pageInfo = await pagenavResponse.json();

          // Include the current language on the link so that the correct menu item activates even when on a non-default language path
          const languages = getLanguages();
          const currentLanguage = getCurrentLanguage();
          const urlLanguage = currentLanguage === languages[0] ? '' : '/' + currentLanguage;

          if (pageInfo.results.length)
            node.linkPath = urlLanguage + pageInfo.results[0]['@path'].replace(sessionStorage.basename, '');
        }
        topNavigation.push(node);
      }
    }

    let footer = {
      link_1: rootComponents.footerLinkOne,
      link_2: rootComponents.footerLinkTwo,
      link_3: rootComponents.footerLinkThree
    }

    this.footer = footer;
    this.topNavigation = topNavigation;
    this.measurementId = measurementId;
    this.stagingMeasurementId = stagingMeasurementId;

    // Enable analytics with the measurement ID specified oon the root page's properties
    // Staging test only analytics
    if ((location.hostname.includes('staging') || location.hostname.includes('localhost')) && !location.href.includes('/.magnolia/') && this.stagingMeasurementId) {
      setOptions({
        config: {id: this.stagingMeasurementId}
      })

      await bootstrap().then(() => localStorage.isTrackingActive = true);
    }
    // Production public instances only analytics
    else if (this.measurementId && !location.hostname.includes('staging') && !location.hostname.includes('author') && !location.hostname.includes('localhost') && !location.href.includes('/.magnolia/')) {
      setOptions({
        config: {id: this.measurementId}
      })

      await bootstrap().then(() => localStorage.isTrackingActive = true);
    }
  },
  methods:{
    addLayer(){
      this.openLayers += 1;
    },
    removeLayer(){
      this.openLayers -= 1;
    },
    getHamburgerNavigation(pagenav){
      pagenav['@nodes'].forEach((nodeName) =>{
        let node = pagenav[nodeName];

        if (node.showOnHamburgerNav && JSON.parse(node.showOnHamburgerNav))
          this.hamburgerNavigation.push(node);

        this.getHamburgerNavigation(node);
      });
    }
  }
};
</script>
<style>
  header{
    position: sticky;
    top:0;
    z-index:1020;
  }
</style>
