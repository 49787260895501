<template>
  <b-row>

    <b-col cols="12" sm="4" class="title">
      <h3>{{section.title}}</h3>
    </b-col>

    <b-col cols="12" sm="8">
      <TextComponent
        v-if="type === 'text'"
        :text="section.switchable.text"
        :switchable="textFakeSwitchable"
        class="text"/>

      <div v-if="type === 'tickets'"
        :id="'tickets_' + section['@id']"
        class="tickets">
      </div>
    </b-col>

  </b-row>
</template>

<script>
  import TextComponent from "./Text";
  import postscribe from 'postscribe';

  export default {
    name: 'TwoColsItem',
    props: ['section'],
    components:{
      TextComponent
    },
    data(){
      return{
        textFakeSwitchable: {field: 'standard'}
      }
    },
    computed:{
      type(){
        return this.section.switchable.field;
      }
    },
    mounted(){
      // Append and activate embeddable tickets widget
      if(this.type === 'tickets'){
        postscribe('#tickets_' + this.section['@id'], this.section.switchable.embed);
      }
    }
  };
</script>

<style scoped lang="scss">
  .title{
    text-align:right;
  }

  .tickets{
    margin-top: -$spacer/4;
  }

  .text{
    margin-top:$spacer/12;
  }

  @include media-breakpoint-down(md) {
    .text{
      margin-top:-$spacer/20;
    }

    .tickets{
      margin-top: -$spacer/3;
    }
  }

  @include media-breakpoint-down(sm) {
    .title{
      text-align:left;
    }
  }
</style>