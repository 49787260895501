import Vue from 'vue';
import VueRouter from 'vue-router';
import VueGtag from "vue-gtag";
import i18n from './i18n/i18n.js'
import {getCurrentLanguage, getLanguages} from './i18n/i18n.js'
import App from './App.vue';
import PageLoader from './helpers/PageLoader.vue';
import {getVueInstance, trackPageView} from "./helpers/AppHelpers";
import './styles/global.scss';
import './fonts/gilroy/definitions.css';
import './fonts/icofont/icofont.min.css';
import LazyLoad from "./directives/LazyLoad";

Vue.config.productionTip = false;
Vue.config.devtools = true;

// Detect browser navigation. used to enable browser navigation in
// conjunction with router-view viewKey system
window.addEventListener('popstate', () => {
    window.popStateDetected = true
})

Vue.directive("lazyload", LazyLoad);
Vue.use(VueRouter);

import { LayoutPlugin, NavbarPlugin } from 'bootstrap-vue';
Vue.use(LayoutPlugin);
Vue.use(NavbarPlugin);

import VueScrollTo from 'vue-scrollto';
Vue.use(VueScrollTo);

const router = new VueRouter({
    mode: 'history',
    base: '/',
    routes: [
        { path: '*', component: PageLoader }
    ],
});

Vue.use(VueGtag, {
    bootstrap: false,
    onReady(){
        localStorage.isTrackingActive = 'true';

        // Take care of any trackings on our backlog that were requested when GA wasn't ready yet
        let missedTrackings = localStorage.missedTrackings ? JSON.parse(localStorage.missedTrackings) : [];

        missedTrackings.forEach(title => {
            trackPageView(this, title);
        })

        localStorage.missedTrackings = '';
    }
});

router.beforeEach((to, from, next) => {

    let app = getVueInstance('app');
    if(app){
        // Tell the app we're navigating to a different route (used in NavigationItem to programmatically hide sidebar)
        app.comingFromDifferentRoute = true;
        //Update router-view on browser navigation
        if(window.popStateDetected)
            app.viewKey+=1;
    }

    window.popStateDetected = false;

    // Add the current language (if non-default) to the path if it's not already included
    // Turns any internal link on the page i18n aware
    const languages = getLanguages();
    const currentLanguage = getCurrentLanguage();

    if(currentLanguage !== languages[0] && !to.path.includes('/' + currentLanguage)){
        next({path: '/' + currentLanguage + to.path, replace: true});
    }
    else
        next();
});

new Vue({
  router,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
