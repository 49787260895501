<template>
  <div class="project" :class="{open: showDetails}">
    <div class="preview" ref="preview" @click="toggleDetails" >

      <div  class="image-section" v-lazyload>
        <b-img v-if="context === 'bosa'" :src="icon" :data-url="icon" @error="onImageError" fluid class="image lazy-img-fadein"></b-img>
        <b-img v-else :src="entry.image" :data-url="entry.image" class="image lazy-img-fadein"></b-img>
      </div>

      <div class="title" :title="entry.project">
        <span>{{entry.project}}</span>
      </div>
    </div>

    <div class="project-detail-section" ref="projectdetails">
      <ProjectDetails :project="entry" :open="showDetails"></ProjectDetails>
    </div>

  </div>
</template>

<script>
  import { BImg } from 'bootstrap-vue';
  import ProjectDetails from "./ProjectDetails";
  import {getHost} from "../../helpers/AppHelpers";

  export default {
    name: 'ProjectsWallItem',
    components: {
      ProjectDetails,
      'b-img': BImg
    },
    props: ['entry'],
    data(){
      return{
        context: 'bosw',
        showDetails: false,
        imageError: false
      }
    },
    mounted(){
      if(document.querySelector('#app').classList.contains('bosa')){
        this.context = 'bosa'
      }
    },
    computed:{
      icon(){
        let icon = `${getHost()}${process.env.VUE_APP_PLACEHOLDER_APP_IMAGE}`;

        if(this.imageError)
          return icon;

        if(this.entry.icon)
          icon = this.entry.icon;
        else if(parseInt(this.entry.year) <= 2015)
          icon = this.entry.image;

        return icon;
      }
    },
    methods:{
      toggleDetails(){
        this.showDetails = !this.showDetails;

        this.$nextTick(function(){
          // Close other open projects
          if(this.showDetails){
            document.querySelectorAll('.project.open').forEach((element) =>{
              let vueComponent = element.__vue__;
              if (vueComponent !== this){
                vueComponent.showDetails = false;
                vueComponent.setStyle(false);
              }
            });
          }

          this.setStyle(this.showDetails);
        })
      },
      setStyle(show){
        let padding = '0';
        let opacity = '0';

        let additionalHeight = 6;

        if(this.context === 'bosa')
          additionalHeight = 40;

        if(show){
          padding = this.$refs.projectdetails.clientHeight;
          opacity = '1';
          this.$refs.preview.style.height = (parseInt(getComputedStyle( this.$refs.preview, null).getPropertyValue('height'))+ additionalHeight) + 'px';
        }
        else{
          this.$refs.preview.style.height = (parseInt(getComputedStyle( this.$refs.preview, null).getPropertyValue('height'))- additionalHeight) + 'px';
        }

        this.$el.style.paddingBottom = padding+'px';
        this.$refs.projectdetails.style.opacity = opacity;

      },
      onImageError(){
        this.imageError = true;
      }
    }
  };
</script>

<style scoped lang="scss">
  .project{
    margin-bottom:60px;
    transition:all 0.2s ease-in-out;

    .preview{
      width:rem(200px);
      height:rem(220px);
      max-width:calc(100% - 60px);
      border:1.5px solid $black;
      padding-top:$spacer/1.5;
      padding-bottom:45px;
      margin:0 30px;
      transition: all 0.2s ease-in-out;
      cursor: pointer;

      .image-section{
        width:100%;
        height:100%;

        img{
          padding:0 $spacer/1.5;
          width:100%;
          height:100%;
          object-fit: contain;
        }
      }

      .title{
        text-transform: uppercase;
        font-weight:500;
        font-style:italic;
        font-size:$smaller-font-size;
        display:flex;
        height:$spacer;
        align-items: center;
        justify-content:center;
        padding:0 $spacer/4;
        padding-top:$spacer/6;

        span{
          display:block;
          white-space: nowrap;
          overflow:hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .project-detail-section{
      height:0;
      width:0;
      overflow:hidden;
      position:absolute;
      left:0;
      opacity:0;
      transition:opacity 0.2s 0.2s ease-in-out;
    }

    &.open{

      .preview{
        border-color:var(--accent-color);
        z-index:3;
        position:relative;
        padding-bottom:85px;

        &::after{
          content:'';
          display:block;
          width:rem(200px - 3.5px);
          height:5px;
          background-color: white;
          z-index:3;
          margin-top:44px;
          position:absolute;
        }
      }

      .project-detail-section{
        height:auto;
        width:auto;
        margin:30px;
        margin-top:-1px;
        padding:$spacer;
        border:1.5px solid var(--accent-color);
        z-index:0;
      }
    }
  }

  .bosw .project{
    margin:rem(3px);

    .preview{
      width:100%;
      max-width:100%;
      height:auto;
      margin:0;
      padding:0;

      .image-section{
        height:rem(200px);

        img{
          padding:0;
          height:100%;
          width:100%;
          object-fit:cover;
        }
      }

      .title{
        padding-top:0;
        line-height:1;
      }
    }

    &.open{
      .preview{
        border-bottom:1.5px solid transparent;

        &::after{
          width:100%;
          margin-top:rem(5px);
        }
      }

      .project-detail-section{
        width:calc(100% - 6px);
        margin:3px;
        margin-top:-1px;
      }
    }
  }

 @include media-breakpoint-down(md) {
   .project{
     margin-bottom:$spacer;

     .preview{
       width:rem(120px);
       max-width:calc(100% - 40px);
       height:rem(140px);
       padding-top:$spacer/2;
       margin:0 20px;

       .image-section{
         img{
           padding:0 $spacer/2;
         }
       }

       .title{
         padding:0 $spacer/4;
         padding-top:0;
       }
     }

     &.open{
       .preview{

         &::after{
           width:rem(120px - 3px);
           margin-top:42px;
         }
       }

       .project-detail-section{
         margin:20px;
         margin-top:-1px;
         padding:$spacer/2;
       }
     }
   }

   .bosw .project{
     .preview{
       .image-section{
         height:rem(140px);
       }
     }
   }
 }

 @include media-breakpoint-down(sm) {
   .project{
     margin-bottom:$spacer/1.5;

     .preview{
       width:auto;
       max-width:calc(100% - 20px);
       height:rem(120px);
       padding-top:$spacer/3;
       padding-bottom:30px;
       margin:0 10px;

       .image-section{
         img{
           padding:0 $spacer/3;
         }
       }

       .title{
         height:$spacer/1.2;
         padding:0 $spacer/3;
         font-size:$smaller-font-size*0.8;
       }
     }

     &.open{
       .preview{
         padding-bottom:70px;

         &::after{
           width:100%;
           margin-top:35px;
         }
       }

       .project-detail-section{
         margin:10px;
         margin-top:-1px;
         padding:$spacer/2;
         border:1.5px solid var(--accent-color);
         z-index:0;
       }
     }
   }

   .bosw .project{
     .preview{
       .image-section{
         height:rem(100px);
       }
     }
   }
 }
</style>