<template>
<div class="centered-wrapper">
  <div class="text-wrapper">
    <b-row v-if="type === 'withImage'">
      <b-col cols="12" sm="3" class="text-center">
        <b-img :src="image" fluid/>
      </b-col>
      <b-col cols="12" sm="8">
        <div v-html="adjustedText"></div>
      </b-col>
    </b-row>

    <b-row v-else>
      <b-col cols="12">
        <div v-html="adjustedText"></div>
      </b-col>
    </b-row>
  </div>
</div>

</template>

<script>
  import { BImg} from 'bootstrap-vue';
  import {getCurrentLanguage, getLanguages} from "../i18n/i18n";
  import { getHost } from "../helpers/AppHelpers";
  export default {
    name: 'TextComponent',
    props: ['text', 'switchable'],
    components:{
      'b-img': BImg
    },
    computed:{
      type(){
        return this.switchable.field || 'standard';
      },
      adjustedText(){
        //Turn links with a '/bosw/<page>' format  into '/<page>'
        // Add the correct language specification to the link
        const languages = getLanguages();
        const currentLanguage = getCurrentLanguage();
        const urlLanguage = currentLanguage === languages[0] ? '' : '/' + currentLanguage;

        return this.text.replace(`href="${sessionStorage.basename}`, `href="${urlLanguage}`);
      },
      image(){
        if(this.type === 'withImage')
          return getHost() + this.switchable.textImage.renditions.large.link;
        else
          return '';
      }

    }
  };
</script>

<style lang="scss">
  .text-wrapper{
    width:100%;
    max-width:1150px;
    padding:0 $spacer;
    padding-bottom:$spacer/2;

    img{
      max-height:120px;
      margin-bottom:$spacer/2;
    }
  }

  .accordion .text-wrapper:last-child{
    padding-bottom:0;
  }

  blockquote {
    background: #f9f9f9;
    border-left: 10px solid #ccc;
    margin: 1.5em 10px;
    padding: 0.5em 10px;
    quotes: "\201C" "\201D" "\2018" "\2019";
  }

  blockquote:before {
    color: #ccc;
    content: open-quote;
    font-size: 4em;
    line-height: 0.1em;
    margin-right: 0.25em;
    vertical-align: -0.4em;
  }

  blockquote p {
    display: inline;
  }

  @include media-breakpoint-down(md){
    .text-wrapper{
      padding-left:$spacer;
      padding-right:$spacer;
    }
  }

  @include media-breakpoint-down(sm){
    .text-wrapper{
      padding-left:$spacer/2;
      padding-right:$spacer/2;

      img{
        max-width:150px;
      }
    }
  }
</style>