import constants from "@/helpers/constants";
import Vue from "vue";
import {getCurrentLanguage, removeCurrentLanguage, getLanguages} from "../i18n/i18n";
import {inMgnlIframe} from "../vue-editor";

export const getVueInstance = function(elementId){
    return window.document.getElementById(elementId).__vue__;
}

export const getRootComponents = async function (){
    // Get the root components from the root page
    const root = await getRestResource('page_content', '');

    if(root){
        let rootcomponents = {};

        // Get the components listed in the ROOT_COMPONENTS constant array
        for (let component of constants.ROOT_COMPONENTS){
            if (root[component])
                rootcomponents[component] = root[component];
        }

        return rootcomponents;
    }
    else
        return;
}

export const hideContentForDetailMode = function(detailMode){
    // Toggle visibility for all the elements on the page based on the detailMode flag and the show-on-detail-mode class
    // In order to hide all the other content on the page when an object detail modal is open

    // Get all the components on the page
    Vue.nextTick(function () {
        let componentsOnPage = document.querySelector('#mainArea').childNodes;

        // On detailMode === true, hide all the components without a "show-on-detail-mode" class
        // On detailMode === false, restore visibility
        for(let component of componentsOnPage){
            if(!detailMode)
                component.classList?.remove('hidden');
            else if(!component.classList?.contains('show-on-detail-mode'))
                component.classList?.add('hidden');
        }
    });
}

export const getLazyLoadImage = function(imageObject){
    const thumbnailUrl = imageObject.renditions.thumbnail.link;
    const largeUrl = imageObject.renditions.large.link;

    const host = getHost();

    const sizes = ['(max-width: 800px) 600px',
                    '1600px'];

    const lazyImage = {
        src: `${host}${largeUrl}`,
        srcset: [`${host}${largeUrl} 1600w`,
                `${host}${thumbnailUrl} 600w`],
        sizes: sizes
    };

    return lazyImage;
}

export const updateViewKey = function(){
    // Enable the app to remount a page even when navigating from the same route, contrary to default router behaviour
    let app = getVueInstance('app');
    app.viewKey += 1;
    app.isHomePage = checkIfHomePage();

    // Programmatically close the sidebar only if coming from a different route
    if (!app.comingFromDifferentRoute){
        let menu = document.querySelector('#hamburger-menu');
        if(menu.style.display !== 'none'){
            let hamburgerToggleButton = document.querySelector('#hamburgerToggleButton');
            hamburgerToggleButton.click();
        }
    }
    app.comingFromDifferentRoute = false;
}

export const scrollToTop = function(){
    setTimeout(() => {document.getElementById('app').scrollIntoView({behavior: "smooth"})}, 0);
}

// Get the basename depending on different scenarios
export const getBasename = async function(){
    let basename = '';

    const urlBasename = getUrlBasedBasename();
    const basenameList = process.env.VUE_APP_SITE_BASENAME_LIST.split(' ');
    const isOffSite = basenameList.includes(urlBasename.replace('/', ''));

    // If on adminCentral and trying to open a page belonging to an off-domain site
    if(inMgnlIframe && isOffSite){
        // Get the basename from the url
        basename = sessionStorage.basename = urlBasename;
    }
    // Else, get the basename from sessionStorage, if already existing, or from the specialized endpoint
    else if(sessionStorage.basename){
        basename = sessionStorage.basename;
    }
    else{
        basename = sessionStorage.basename = await getHostBasedBasename();
    }

    return basename;
}

// Get the basename based on the host
export const getHostBasedBasename = async function(){
    const sitesResponse = await fetch(process.env.VUE_APP_HOST + '/.rest/delivery/sites/v1/modules/multisite/config/sites');

    const sites = await sitesResponse.json();
    let mappings = {};
    sites['@nodes'].forEach(siteName => {
        let site = sites[siteName];
        if (site.domains.default) {
          mappings[site.domains.default.name] = site.mappings.home.handlePrefix;
        }
    });

    let basename = mappings[location.hostname] || process.env.VUE_APP_SITE_BASENAME;
    return basename;
}

// Get the basename from the url
export const getUrlBasedBasename = function(){
    let basename = '/' + location.pathname.split('/')[1].replace('.html', '');

    // Fix for the case where the domain name gets added to the pathname when on adminCentral
    // If on adminCentral, use the parent location instead
    if (inMgnlIframe) {
        basename = '/' + window.parent.location.hash.split(";")[1].split("/")[1].split(':')[0].toLowerCase()
    }

    return basename;
}

export const checkIfHomePage = function(){
    let splitLocation = location.pathname.split('/');
    const languages = getLanguages();

    //true if pathname is: '/', '/<lang>/', '/path/to/<home-page>'
    return location.pathname === '/' || languages.includes(location.pathname.replace(/\//g, '')) || splitLocation[splitLocation.length-1].toLowerCase() === Vue.prototype.$homePage;
}

// Manually trigger a pageview tracking event with a custom template
export const trackPageView = function(self, title){
    // If the GA tracking is not active yet, create a backlog of trackings that
    // will be taken care of once the GA tracking is ready
    if(localStorage.isTrackingActive === 'false'){
        let missedTrackings = localStorage.missedTrackings ? JSON.parse(localStorage.missedTrackings) : [];
        missedTrackings.push(title);
        localStorage.missedTrackings = JSON.stringify(missedTrackings);
        return;
    }

    // If for some reason the self instance is undefined, just use the app one
    const app = getVueInstance('app');
    self = self ? self : app;

    // Trigger the pageview event
    self.$gtag.pageview({
        page_path: location.pathname,
        page_title: title,
        page_location: location.href
    });
}

// Try getting a rest resource by trying with both a standard basename and a host based basename
export const getRestResource = async function(endpoint, pageUrl){
    let url = '';

    let basename = sessionStorage.basename;

    switch(endpoint){
        case 'page_content':
            url = process.env.VUE_APP_HOST + process.env.VUE_APP_REST_PAGES;
            break;
        case 'pagenav':
            url = process.env.VUE_APP_HOST + process.env.VUE_APP_REST_PAGENAV;
    }

    if(url){
        const currentLanguage = getCurrentLanguage();

        let path = url + basename + pageUrl;

        path = removeCurrentLanguage(path, currentLanguage);
        path += "?lang=" + currentLanguage;

        let restResponse = await fetch(path);

        if(!restResponse.ok){
            basename = await getHostBasedBasename();

            let path = url + basename + pageUrl;

            path = removeCurrentLanguage(path, currentLanguage);
            path += "?lang=" + currentLanguage;

            restResponse = await fetch(path);

            if (!restResponse.ok)
                return;
        }

        return await restResponse.json();
    }
    else
        return;
}

export const getHost = function(){
    return location.origin === 'http://bosw:8080' || location.origin === 'http://bosa:8080' ? '' : process.env.VUE_APP_HOST;
}

// Checks if a DOM element is overflowing in its width
export const isOverflownWidth = function(element){
    return element.scrollWidth > element.clientWidth;
}

// Replace whitespaces with dashes in order to generate a supported id
export const formatAnchor = function(anchor){
    return anchor.replace(/\s+/g, '-').toLowerCase();
}