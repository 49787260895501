<template>
  <EditablePage
    v-if="content"
    v-bind:content="content"
    v-bind:config="config"
    v-bind:templateDefinitions="templateDefinitions"
  />
</template>

<script>
import Vue from "vue";
import config from "../magnolia.config";
import { EditablePage, inEditorEdit, inMgnlIframe } from "../vue-editor";
import {
  getRestResource,
  getVueInstance,
  getHost
} from "../helpers/AppHelpers";

import {
  getCurrentLanguage,
  removeCurrentLanguage
} from "../i18n/i18n"

const getContentUrl = async () => {

  const currentLanguage = getCurrentLanguage();

  let nodeName = sessionStorage.basename;
  let isRootPage = !location.href.toLowerCase().includes('mgnlpreview') && removeCurrentLanguage(location.pathname, currentLanguage) === '/';
  localStorage.isRootPage = isRootPage;

  let path = '';

  if(isRootPage){
    path = nodeName + '/' + Vue.prototype.$homePage
  }
  else{
    path =
      nodeName +
      window.location.pathname.toLowerCase().replace(
        new RegExp("(.*" + nodeName.toLowerCase() + "|.html)", "g"),
        ""
      );
  }

  // Fix for the case where the domain name gets added to the pathname when on adminCentral
  // If on adminCentral, use the parent location instead
  if (inMgnlIframe) {
    path = window.parent.location.hash.split(";")[1].split(":")[0].toLowerCase();
  }

  path = removeCurrentLanguage(path, currentLanguage);
  path += "?lang=" + currentLanguage;

  return getHost() + process.env.VUE_APP_REST_PAGES + path;
};

export default {
  name: "PageLoader",
  components: {
    EditablePage
  },
  data: function() {
    return {
      content: undefined,
      config,
      templateDefinitions: {}
    };
  },
  methods: {
    async loadPage() {
      let app = getVueInstance('app');

      let contentResponse = await fetch(await getContentUrl());
      let content = null;

      // If we have a 404, get the 404 page content with the current basename
      if(contentResponse.status === 404){
        localStorage.is404 = true;

        content = await getRestResource('page_content', '/404');
      }
      else{
        localStorage.is404 = false;
        content = await contentResponse.json();
      }

      if (inEditorEdit) {
        const templateDefinitionsResponse = await fetch(
            process.env.VUE_APP_HOST + process.env.VUE_APP_REST_TEMPLATE_DEFINITION +
            content["mgnl:template"]
        );
        const templateDefinitions = await templateDefinitionsResponse.json();

        this.templateDefinitions = templateDefinitions;
      }

      // Reset app detail mode on each page load
      app.openLayers = 0;

      this.content = content;
    }
  },
  mounted() {
    this.loadPage();
  },
  updated() {
    if (inEditorEdit) window.parent.mgnlRefresh();
  }
};
</script>
