import Root from './pages/Root';
import Child from './pages/Child';
import TextComponent from './components/Text';
import NavigationItem from './components/NavigationItem';
import Newsletter from './components/Newsletter';
import Timeline from "./components/Timeline";
import CTAList from "./components/CTAList";
import FlickrGallery from "./components/FlickrGallery";
import Accordion from './components/Accordion';
import PageContent from './components/PageContent';
import VideoComponent from './components/Video';
import Headline from './components/Headline';
import PartnersComponent from "./components/Partners";
import PartnersBar from "./components/PartnersBar";
import Hero from "./components/Hero";
import Anchor from "./components/Anchor";
import Profiles from "./components/Profiles";
import TwoCols from "./components/TwoCols";
import Image from "./components/Image";
import Link from "./components/Link";
import HallOfFame from "./components/hallOfFame/HallOfFame";
import Shortlist from "./components/hallOfFame/Shortlist";
import Masternominationen from "./components/hallOfFame/Masternominationen";

const config = {
  componentMappings: {
    // Pages
    'kubusmedia-content-blocks:pages/root': Root,
    'kubusmedia-content-blocks:pages/child': Child,

    // Components
    'kubusmedia-content-blocks:components/text': TextComponent,
    'kubusmedia-content-blocks:components/navigationItem': NavigationItem,
    'kubusmedia-content-blocks:components/accordion': Accordion,
    'kubusmedia-content-blocks:components/video': VideoComponent,
    'kubusmedia-content-blocks:components/anchor': Anchor,
    'kubusmedia-content-blocks:components/twocols': TwoCols,
    'kubusmedia-content-blocks:components/image': Image,
    'kubusmedia-content-blocks:components/link': Link,

    //BOSW components
    'bosw-custom:components/pageContent': PageContent,
    'bosw-custom:components/newsletter': Newsletter,
    'bosw-custom:components/timeline': Timeline,
    'bosw-custom:components/ctaList': CTAList,
    'bosw-custom:components/flickrGallery': FlickrGallery,
    'bosw-custom:components/headline': Headline,
    'bosw-custom:components/partners': PartnersComponent,
    'bosw-custom:components/partnersBar': PartnersBar,
    'bosw-custom:components/hero': Hero,
    'bosw-custom:components/profiles': Profiles,
    'bosw-custom:components/hallOfFame': HallOfFame,
    'bosw-custom:components/shortlist': Shortlist,
    'bosw-custom:components/masternominationen': Masternominationen
  },
};

export default config;
