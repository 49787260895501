<template>
  <div>
    <Headline :headline="headline"></Headline>
    <div class="timeline-container component-margin">
      <div
          :class="['timeline-element ' + getTimelineElementClass(element), {'with-link': hasLink(element)}]"
          v-for="(element) in elements"
          :key="element['@id']">

        <div class="h2 content-text" v-if="getTimelineElementClass(element).indexOf('grow') >= 0" :title="element.title">{{element.title}}</div>
        <div class="h6 content-text" v-if="getTimelineElementClass(element).indexOf('grow') === -1" :title="element.title">{{element.title}}</div>

        <div class="date content-text">
          <span class="day-text"  v-html="formatStartDate(element.startDate, 'day')"></span>
          <span class="month-text"  v-html="formatStartDate(element.startDate, 'month')"></span>
          <span class="year-text"  v-html="formatStartDate(element.startDate, 'year')"></span>
        </div>

        <AdvancedLink
            v-if="hasLink(element)"
            :link="element.link"
            class="content-text link">
          <span>{{element.linkText}}</span>
          <span class="icon arrow-right" v-html="iconArrow"></span>
        </AdvancedLink>

        <div>
          <span class="icon" v-html="timelineBadge"></span>
          <span class="icon base" v-html="timelineBadge"></span>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
  import {timelineBadge, iconArrow} from '../styles/icons';
  import Headline from "./Headline";
  import AdvancedLink from "./AdvancedLink";

  export default {
    name: 'Timeline',
    props: ['headline', 'elements'],
    components: {
      Headline,
      AdvancedLink,
    },
    data() {
      return {
        timelineBadge: timelineBadge,
        iconArrow: iconArrow
      }
    },
    methods: {
      getTimelineElementClass(element) {
        const now = new Date();
        const startDate = new Date(element.startDate);
        const endDate = new Date(element.endDate);
        let style = '';

        if (endDate > now) {
          style += 'highlighted ';
        }

        if (this.getClosestDate()) {
          if (this.getClosestDate().getTime() === startDate.getTime()) {
            style += 'grow ';
          }
        }

        return style;
      },
      getClosestDate() {
        let closestDate;
        const now = new Date();

        this.elements.forEach(element => {
          const startDate = new Date(element.startDate);
          if (!closestDate && startDate > now) {
            closestDate = startDate;
          } else if (closestDate > startDate && startDate > now) {
            closestDate = startDate
          }
        });

        return closestDate;
      },
      formatTitle(title) {
        const parts = title.split(' ');
        let skipped = 0;

        for (let i = 0; i < parts.length; i++) {
          if (parts[i].length > 3 || skipped + parts[i].length >= 5) {
            parts[i] += '<br />';
            skipped = 0;
          } else {
            skipped += parts[i].length;
          }
        }

        return parts.join(' ');
      },
      formatStartDate(date, part) {
        const dateValue = new Date(date);

        switch(part) {
          case 'day':
            return ('0' + dateValue.getDate()).slice(-2)
          case 'month':
            return this.$t('timeline.months')[dateValue.getMonth()];
          case 'year':
            return dateValue.getFullYear();
        }
      },
      hasLink(element){
        return element.link.type && element.link.type !== 'none';
      }
    }
  }
</script>

<style scoped lang="scss">
  .timeline-container {
    display: flex;
    flex-direction: row;
    flex-shrink: 1;
    align-items: flex-start;
    justify-content: center;
    width: 100%;

    .timeline-element {
      width: 7vw;
      height: auto;
      margin-top: 1vw;
      position: relative;
      text-transform: uppercase;
      font-weight: 300;
      transition:$transition-base;

      /deep/ svg {
        width: 100%;
        height: auto;
      }

      .icon.base{
        /deep/ svg{
          position:absolute;
          top:-1px;
          left:-1.3px;
          width: calc(100% + 2px);
          z-index:-1;
          color:$white;
        }
      }

      .content-text {
        position: absolute;
        width: 100%;
        text-align: center;
        color: $white;
        hyphens:auto;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -moz-box-orient: vertical;
        -ms-box-orient: vertical;
        box-orient: vertical;
        -webkit-line-clamp: 3;
        -moz-line-clamp: 3;
        -ms-line-clamp: 3;
        line-clamp: 3;
        overflow: hidden;
      }

      .h2 {
        top: 2.05vw;
        font-size: 1.8vw !important;
        line-height: 2vw;
        font-weight:400;
        padding:0 0.7vw;
      }

      .h6 {
        top: 0.5vw;
        font-size: 0.9vw;
        font-weight:400;
        padding:0 0.5vw;
      }

      .date{
        display:flex;
        flex-direction:column;
        align-items: center;
        line-height:1;
        top:3.7vw;
        transition:$transition-base;

        span{
          transition:$transition-base;
        }

        .day-text {
          font-size: 2vw;
        }

        .month-text {
          font-size: 1.2vw;
        }

        .year-text {
          font-size: 0.9vw;
        }
      }

      .link{
        display:flex;
        flex-direction:column;
        align-items: center;
        justify-content: flex-end;
        color:$white !important;
        font-size:0.9vw*0.8;
        font-weight:700;
        font-style:italic;
        bottom:1vw;
        border-bottom:none;
        line-height:1;
        opacity:0;
        transition:$transition-fade;

        span{
          display:block;
          width:70%;
        }

        .icon{
          margin-top:3px;

          /deep/ svg{
            height:0.9vw;
            width:0.9vw;
          }
        }
      }

      &:hover,
      &:focus-within{
        transform: scale(1.1);
        z-index:3;

        &.with-link{
          .date{
            .day-text {
              font-size: 2vw*0.6;
            }

            .month-text {
              font-size: 1.2vw*0.6;
            }

            .year-text {
              font-size: 0.9vw*0.6;
            }
          }

          .link{
            opacity:1;
          }
        }
      }

      &.highlighted {
        width: 9.73vw;
        height: auto;

        /deep/ svg {
          color: var(--accent-color);
        }

        .h6 {
          padding:0 0.7vw;
        }

        .date{
          top:4vw;
          line-height:0.85;

          .day-text {
            font-size: 3vw;
          }

          .month-text {
            font-size: 2vw;
          }

          .year-text {
            font-size: 1.5vw;
          }
        }

        .link{
          color: $black !important;
          font-size:1vw;
          bottom:1.5vw;
          line-height:0.85;

          .icon{
            /deep/ svg{
              color:$black;
              height:1.8vw;
              width:1.8vw;
            }
          }
        }

        &:hover,
        &:focus-within{
          &.with-link{
            .date{
              .day-text {
                font-size: 3vw*0.6;
              }

              .month-text {
                font-size: 2vw*0.5;
              }

              .year-text {
                font-size: 1.5vw*0.5;
              }
            }

            .link{
              opacity:1;
            }
          }
        }
      }

      &.grow {
        width: 16.77vw;
        height: auto;
        margin-top: 0;
        font-style: italic;
        font-weight: 500;

        .h2{
          font-weight:500;
        }

        .date{
          top:9.5vw;

          .day-text {
            font-size: 5vw;
          }

          .month-text {
            font-size: 3.2vw;
          }

          .year-text {
            font-size: 2.7vw;
          }
        }

        .link{
          font-size:1.8vw;
          bottom:1.7vw;
          line-height:0.9;

          .icon{

            /deep/ svg{
              height:3vw;
              width:3vw;
            }
          }
        }

        &:hover,
        &:focus-within{
          &.with-link{
            .date{
              top:8.5vw;

              .day-text {
                font-size: 5vw*0.6;
              }

              .month-text {
                font-size: 3.2vw*0.5;
              }

              .year-text {
                font-size: 2.7vw*0.5;
              }
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .timeline-container {
      .timeline-element {
        width: 11.03vw;
        margin-top: 2vw;

        .h2 {
          top: 2.5vw;
          font-size: 2.8vw !important;
          line-height: 3vw !important;
        }

        .h6 {
          top: 1.5vw;
          font-size: 1.4vw;
          line-height:1.1;
        }

        .date{
          top:6vw;
          line-height:0.9;

          .day-text {
            font-size: 3.5vw;
          }

          .month-text {
            font-size: 2vw;
          }

          .year-text {
            font-size: 1.7vw;
          }
        }

        .link{
          font-size:1.5vw;
          bottom:1.5vw;
          line-height:0.5;

          .icon{
            /deep/ svg{
              height:1.8vw;
              width:1.8vw;
            }
          }
        }

        &:hover,
        &:focus-within{
          &.with-link{
            .date{
              line-height:0.9;

              .day-text {
                font-size: 3vw*0.6;
              }

              .month-text {
                font-size: 1.8vw*0.6;
              }

              .year-text {
                font-size: 1.7vw*0.6;
                line-height:1.3;
              }
            }
          }
        }

        &.highlighted {
          width: 13.73vw;

          .h6 {
            top: 1.3vw;
            font-size: 1.5vw;
          }

          .date{
            top:6.3vw;
            line-height:0.9;

            .day-text {
              font-size: 5vw;
            }

            .month-text {
              font-size: 2.5vw;
            }

            .year-text {
              font-size: 2.3vw;
              line-height:1.1;
            }
          }

          .link{
            font-size:1.5vw;
            bottom:1.5vw;
            line-height:0.85;

            .icon{
              /deep/ svg{
                height:1.8vw;
                width:1.8vw;
              }
            }
          }

          &:hover,
          &:focus-within{
            &.with-link{
              .date{
                .day-text {
                  font-size: 5vw*0.6;
                }

                .month-text {
                  font-size: 2.5vw*0.6;
                }

                .year-text {
                  font-size: 2.3vw*0.6;
                }
              }
            }
          }
        }

        &.grow {
          width: 24vw;

          .date{
            top:12.5vw;
            line-height:0.9;

            .day-text {
              font-size: 9vw;
            }

            .month-text {
              font-size: 4vw;
            }

            .year-text {
              font-size: 3.5vw;
              line-height:1.1;
            }
          }

          .link{
            font-size:2vw;
            bottom:2vw;

            .icon{
              /deep/ svg{
                height:3vw;
                width:3vw;
              }
            }
          }

          &:hover,
          &:focus-within{
            &.with-link{
              .date{
                top:12.5vw;

                .day-text {
                  font-size: 9vw*0.6;
                }

                .month-text {
                  font-size: 4vw*0.6;
                }

                .year-text {
                  font-size: 3.5vw*0.6;
                }
              }
            }
          }
        }
      }
    }
  }
</style>