<template>
  <div class="wrapper component-margin">
    <div class="partner-group-title">
      {{ title }}
    </div>
    <div class="partners-container">
      <div class="partners-row" v-for="(partnerGroup, index) in groupedPartners()" :key="index">
        <div class="single-partner"
             v-for="(singlePartner) in partnerGroup"
             :key="singlePartner['@id']"
             :style="getMargins(singlePartner)"
        >
          <a :href="singlePartner.partner.link" target="_blank">
            <img @mouseover="grow($event)" @mouseout="shrink($event)" :src="getImage(singlePartner)"/>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getHost} from "../helpers/AppHelpers";

export default {
  name: 'PartnersComponent',
  props: ['partner', 'title'],
  methods: {
    getImage(imageJson) {
      let partnerImage = JSON.parse(imageJson.partner.image);
      return getHost() + partnerImage.metadata.renditions.large.link;
    },
    groupedPartners() {
      let groupedPartners = [];
      let currentGroup = [];

      if (Array.isArray(this.partner)) {
        this.partner.forEach((p) => {
          currentGroup.push(p);
          if (p.newLine === 'true') {
            groupedPartners.push(currentGroup);
            currentGroup = [];
          }
        });
        if (currentGroup.length > 0) {
          groupedPartners.push(currentGroup);
        }
      } else {
        groupedPartners.push([this.partner]);
      }
      return groupedPartners;
    },
    getMargins(partner) {
      let style = '';
      style += 'margin-left: ' + partner.margins.left + 'px; ';
      style += 'margin-top: ' + partner.margins.top + 'px; ';
      style += 'margin-bottom: ' + partner.margins.bottom + 'px; ';
      style += 'margin-right: ' + partner.margins.right + 'px; ';

      return style;
    },
    grow(e) {
      e.target.style.transform = 'scale(1.1)';
    },
    shrink(e) {
      e.target.style.transform = 'scale(1)';
    }
  }
};
</script>

<style scoped lang="scss">
  .wrapper{
    display:flex;
    flex-direction:column;
    align-items:center;
    margin-left: rem(50px);
    margin-right: rem(50px);
    max-width: rem(1920px);
  }

  .partner-group-title {
    text-align: center;
    font-weight: 300;
    text-transform: uppercase;
    font-style: italic;
    margin-bottom: $spacer;
  }
  .partners-row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    flex-shrink: 1;
    align-items: center;
    justify-content: center;
    margin-bottom: $spacer;
    width: 100%;

    .single-partner {
      text-align: center;
      max-width: $spacer * 5;
      max-height: $spacer * 2;
      cursor: pointer;
      a {
        text-decoration: none;
        border-bottom: 0;
      }
      img {
        filter: brightness(0);
        transition: all .1s ease-out;
        max-height: $spacer * 2;
        max-width: 100%;
        &:hover {
          filter: unset;
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    .partners-row {
      flex-wrap: wrap;
    }
  }

  @include media-breakpoint-down(sm) {
    .wrapper{
      margin-left: rem(10px);
      margin-right: rem(10px);
    }

    .partners-row {
      flex-wrap: wrap;
      .single-partner {
        max-width: $spacer * 2;
        max-height: $spacer;

        img {
          max-height: $spacer;
        }
      }
    }
  }
</style>