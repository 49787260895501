export default {
  newsletter: {
    subscribe: 'Inscription Newsletter',
    form_title: 'Je souhaite m\'abonner à la newsletter',
    name: 'Prénom',
    surname: 'Nom de famille',
    accept_data: 'J\'accepte le règlement sur la protection des données',
    submission_success: 'Félicitations, vous avez été inscrit !',
    submission_error: 'Désolé, quelque chose s\'est mal passé.',
    already_subscribed: 'Cette adresse a déjà été inscrite.',
    validation:{
      required: 'Ce critère est obligatoire',
        email: 'Veuillez utiliser une adresse valide',
        accept_data: 'Vous devez accepter la politique de confidentialité pour vous inscrire.'
    },
    bosw:{
      extra_subscription_1: 'Je m\'abonne également à la newsletter „Best of Swiss Apps“.',
      extra_subscription_2: 'Je m\'abonne également à la newsletter „Best of Swiss Software“.'
    },
    bosa:{
      extra_subscription_1: 'Je m\'abonne également à la newsletter „Best of Swiss Web“.',
      extra_subscription_2: 'Je m\'abonne également à la newsletter „Best of Swiss Software“.'
    },
    boss:{
      extra_subscription_1: 'Je m\'abonne également à la newsletter „Best of Swiss Web“.',
      extra_subscription_2: 'Je m\'abonne également à la newsletter „Best of Swiss Apps“.'
    }
  },
  footer: {
    telephone: 'Téléphone',
    media: 'Contact presse',
    privacy_link: '',
    terms_link: '',
    impressum_link: '',
  },
  timeline: {
    months: ['JANV.', 'FÉVR', 'MARS', 'APR.', 'MAI', 'JUN', 'JULL.', 'AOÛT', 'SEPT.', 'OCT.', 'NOV.', 'DÉC.']
  },
  filter:{
    all_years: 'Toutes les années',
    all_categories: 'Toutes les catégories',
    all_awards: 'Tous les prix',
    gold: 'Lauréat'
  },
  client: 'Mandant',
  contractor: 'Exécutant',
  cookieConsent: 'Ce site Web utilise des cookies afin d\'optimiser et proposer un meilleur service. En continuant votre navigation sur ce site Web, vous acceptez l\'utilisation de ces cookies.'
}