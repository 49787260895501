<template>
  <transition name="fade">
    <div v-if="open"
         class="overlay"
         :style="{backgroundColor: backgroundColor}">

      <b-button
          variant="transparent"
          @click="toggle"
          :style="{color: buttonColor}">
        <span class="icon" v-html="iconClose"></span>
      </b-button>

      <slot></slot>

    </div>
  </transition>
</template>

<script>
  import { BButton } from 'bootstrap-vue';
  import { iconClose } from "../styles/icons";

  export default {
  name: 'Overlay',
  props: ['background-color', 'button-color'],
  components: {
    'b-button': BButton
  },
  data(){
    return{
      open: false,
      iconClose: iconClose
    }
  },
  methods:{
    toggle(){
      this.open = !this.open;
      if(this.open){
        document.body.classList.add('noscroll');
        this.$emit('overlay-opened');
      }
      else{
        document.body.classList.remove('noscroll');
        this.$emit('overlay-closed');
      }
    }
  }
};
</script>

<style scoped lang="scss">
  .overlay{
    position:fixed;
    top:0;
    bottom:0;
    height:100vh;
    width:100vw;
    z-index: 1037;
    padding:$spacer/2;
    overflow:auto;

    .btn{
      position:absolute;
      right:$spacer/2;
      padding:0;
    }
  }
</style>