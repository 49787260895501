<template>
  <div class="social-wrapper">

    <div class="social-links">
      <b-link class="social-link"
              v-if="facebook"
              :href="facebook"
              target="_blank"
              rel="noopener">
        <i class="icofont-facebook"></i>
      </b-link>
      <b-link class="social-link"
              :href="linkedin"
              v-if="linkedin"
              target="_blank"
              rel="noopener">
      <span class="linkedin">
        <i class="icofont-linkedin"></i>
      </span>
      </b-link>
      <b-link class="social-link"
              :href="twitter"
              v-if="twitter"
              target="_blank"
              rel="noopener">
        <i class="icofont-twitter"></i>
      </b-link>
      <b-link class="social-link"
              :href="flickr"
              v-if="flickr"
              target="_blank"
              rel="noopener">
        <i class="icofont-brand-flikr"></i>
      </b-link>
      <b-link class="social-link"
              :href="youtube"
              v-if="youtube"
              target="_blank"
              rel="noopener">
        <i class="icofont-youtube-play"></i>
      </b-link>
    </div>

    <div class="hashtag">
      {{'#'+ context}}
    </div>

  </div>
</template>

<script>
import { BLink } from 'bootstrap-vue';

export default {
  name: 'SocialLinks',
  components: {
    'b-link': BLink
  },
  data(){
    return{
      facebook: 'https://www.facebook.com/bestofswissweb',
      linkedin: 'https://www.linkedin.com/company/bestofswissweb',
      twitter: 'https://twitter.com/bestofswissweb',
      flickr: 'https://www.flickr.com/photos/20696196@N08/albums/with/72157677773887307',
      youtube: 'https://www.youtube.com/channel/UC-4ypopMdSi9sKl197p9N1A',
      context: 'bosw'
    }
  },
  mounted(){
    if(document.querySelector('#app').classList.contains('bosa')){
      this.context = 'bosa';
      this.facebook = 'https://www.facebook.com/bestofswissapps';
      this.linkedin = 'https://www.linkedin.com/company/best-of-swiss-apps';
      this.twitter = 'https://twitter.com/bestofswissapps';
      this.flickr = 'https://www.flickr.com/photos/bosa2013/albums';
      this.youtube = 'https://www.youtube.com/channel/UCHxDM4TE8mQ6FuuAZI6M_JA';
    } else if(document.querySelector('#app').classList.contains('boss')){
      this.context = 'boss';
      this.facebook = 'https://www.facebook.com/profile.php?id=61560628077066&locale=de_DE';
      this.linkedin = 'https://www.linkedin.com/company/104031706/admin/feed/posts/';
      this.twitter = false;
      this.flickr = false;
      this.youtube = false;
    }
  }
};
</script>
<style scoped lang="scss">
  .social-wrapper {
    display:flex;
    flex-direction:row;
    align-items:center;
    padding-bottom:$spacer/2;

    .hashtag{
      display:none;
    }

    .social-links{
      display:flex;
      flex-direction:row;
      color: white;
      padding-bottom:$spacer/4;

      .social-link{
        height:rem(75px);
        width:rem(75px);
        background-color:var(--accent-color);
        color: white;
        margin:$spacer/4;
        border:3px solid var(--accent-color);
        border-radius:50%;
        display:flex;
        justify-content: center;
        align-items:center;
        transition: $transition-base;
        font-size:rem(36px);

        // Simulate boxless icon for Linkedin
        .linkedin{
          display:block;
          position:relative;
          height:rem(40px);
          width:rem(40px);
          font-size:rem(52px);
          background-color: white;
          color:var(--accent-color);
          transition: $transition-base;

          i{
            position:absolute;
            top:rem(-6px);
            left:rem(-5px);
          }
        }

        &:first-child{
          margin-left:0;
        }

        &:hover{
          background-color: white;
          color: var(--accent-color) !important;

          // Simulate boxless icon for Linkedin
          .linkedin{
            background-color: var(--accent-color);
            color:white;
          }
        }
      }
    }
  }

  footer .social-wrapper{
    padding-left: $spacer;
    padding-bottom:0;
    flex-wrap:wrap;

    .hashtag{
      display:block;
      color:$footer-color;
      font-size:1.25rem;
      font-weight:200;
      padding-bottom:$spacer/4;
    }

    .social-links{
      color: $black;
      padding-right: $spacer/2;

      .social-link{
        height:rem(50px);
        width:rem(50px);
        background-color:white;
        margin:$spacer/8;
        border:none;
        font-size:rem(24px);

        // Simulate boxless icon for Linkedin
        .linkedin{
          height:rem(26px);
          width:rem(26px);
          font-size:rem(34px);
          background-color: $black;
          color:white;

          i{
            top:rem(-4px);
            left:rem(-4px);
          }
        }

        &:hover{
          background-color: var(--accent-color);
          color: white !important;

          // Simulate boxless icon for Linkedin
          .linkedin{
            background-color: white;
            color:var(--accent-color);
          }
        }
      }
    }
  }

  @include media-breakpoint-down(sm){
    .social-wrapper{
      .social-links{
        .social-link{
          height:rem(60px);
          width:rem(60px);
          margin:$spacer/8;
          font-size:rem(24px);

          // Simulate boxless icon for Linkedin
          .linkedin{
            height:rem(26px);
            width:rem(26px);
            font-size:rem(34px);

            i{
              top:rem(-4px);
              left:rem(-4px);
            }
          }
        }
      }
    }
  }
</style>