<template>
  <b-link class="partner" :href="partner.link" target="_blank">
    <div class="img-wrapper">
      <b-img :src="image" fluid/>
    </div>

    <div class="description">
      <span>{{partner.type}}</span>
      <span>{{context}}</span>
    </div>
  </b-link>
</template>

<script>
  import { BImg, BLink } from 'bootstrap-vue';
  import {getHost} from "../helpers/AppHelpers";

  export default {
    name: 'PartnersBarItem',
    props: ['partner'],
    components:{
      'b-img': BImg,
      'b-link': BLink
    },
    data(){
      return{
        context: 'Best of Swiss Web'
      }
    },
    computed:{
      image(){
        const image = JSON.parse(this.partner.image);
        return getHost() + image.metadata.renditions.thumbnail.link;
      }
    },
    mounted(){
      if(document.querySelector('#app').classList.contains('bosa'))
        this.context = 'Best of Swiss Apps';
      else if(document.querySelector('#app').classList.contains('boss'))
        this.context = 'Best of Swiss Software';
    }
  };
</script>

<style scoped lang="scss">
  .partner{
    min-width:0; // Ensures the elements shrink past 100px on iOS devices
    padding:0 $spacer/4;
    display:flex;
    align-items: center;
    justify-content: center;
    flex-grow:1;
    flex-basis:0;
    transition:$transition-base;
    cursor:pointer;
    border:none;

    .img-wrapper{
      display:flex;
      align-items:center;
      height:rem(90px);
      padding:$spacer/2 0;
      transition: $transition-base;

      img{
        max-height: 100%;
        height:auto;
        width:auto;
        filter: brightness(0);
      }
    }

    .description{
      width:0;
      overflow:hidden;
      display:flex;
      flex-direction:column;
      font-size:$smaller-font-size*0.7;
      text-transform: uppercase;
      color:$dark-grey;
      transition: $transition-base;
      opacity:0;

      span{
        white-space: nowrap;
      }
    }

    &:hover{
      padding:0 $spacer;
      flex-grow:6;

      .img-wrapper{
        padding:$spacer/4 0;

        img{
          filter:none;
        }
      }

      .description{
        padding-left:$spacer/2;
        width:auto;
        overflow:visible;
        opacity:1;
      }
    }
  }

  @include media-breakpoint-down(md){
    .partner{
      padding:0 $spacer/6;

      .img-wrapper{
        height:rem(50px);
        padding:$spacer/4 0;
      }

      &:hover{
        padding:0 $spacer/4;

        .img-wrapper{
          padding:$spacer/10 0;
        }

        .description{
          padding-left:$spacer/4;
        }
      }
    }
  }

  @include media-breakpoint-down(sm){
    .partner{
      padding:0 $spacer/6;

      .img-wrapper{
        height:rem(50px);
        padding:$spacer/4 0;
      }

      &:hover{
        padding:0 $spacer/4;

        .img-wrapper{
          padding:$spacer/10 0;
        }

        .description{
          padding-left:$spacer/4;
        }
      }
    }
  }
</style>