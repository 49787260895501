<template>
  <li class="nav-item">
    <a v-if="item.link_external" :href="item.linkPath" class="nav-link" @click="updateViewKey" target="_blank" rel="noopener noreferrer">
      <div class="image-wrapper">
        <b-img :src="icon_grey" :alt="item.title" class="grey-image"></b-img>
        <b-img :src="icon" :alt="item.title" ></b-img>
      </div>
    </a>
    <router-link
        v-else
        class="nav-link"
        :class="{'router-link-active':isHomePage && isHomeLink}"
        :to="item.linkPath"
        @click.native="updateViewKey">

      <div class="image-wrapper">
        <b-img :src="icon_grey" :alt="item.title" class="grey-image"></b-img>
        <b-img :src="icon" :alt="item.title" ></b-img>
      </div>

    </router-link>
  </li>
</template>

<script>
import { BImg } from 'bootstrap-vue';
import {updateViewKey, getHost} from "../helpers/AppHelpers";
import Vue from "vue";

export default {
  name: 'NavigationItem',
  props:['item', 'isHomePage'],
  components:{
    'b-img': BImg
  },
  data(){
    return{
      isHomeLink: this.item.linkPath.includes('/'+Vue.prototype.$homePage)
    }
  },
  computed:{
    icon (){
      return getHost() + this.item.icon.renditions.thumbnail.link;
    },
    icon_grey(){
      return getHost() + this.item.icon_grey.renditions.thumbnail.link;
    }
  },
  methods:{
    updateViewKey: function(){
      updateViewKey();
    }
  }
};
</script>
<style lang="scss" scoped>
  .nav-link{
    padding:0 $spacer/3 !important;
    border-bottom:none;

    .image-wrapper{
      position:relative;

      /* Default: grey image on, coloured image off */
      img{
        height:auto;
        max-height:rem(65px) !important;
        transition:$transition-base;
        position:absolute;
        opacity:0;
        top:0;
        left:0;

        &.grey-image{
          position:static;
          opacity:1;
        }
      }

      /* On hover: grey image off, coloured image on */
      &:hover{
        img{
          position:static;
          opacity:1;

          &.grey-image{
            position:absolute;
            opacity:0;
          }
        }
      }
    }

    /* For the active link: grey image off, coloured image on */
    &.router-link-active{
      img{
        position:static;
        opacity:1;

        &.grey-image{
          position:absolute;
          opacity:0;
        }
      }
    }
  }

  @include media-breakpoint-down(sm){
    .nav-link{
      padding:0 $spacer/8 !important;

      .image-wrapper{
        img{
          max-height:rem(35px) !important;
        }
      }
    }
  }
</style>