<template>
  <div class="form-wrapper">

    <div v-if="!submitted" class="form-container">
      <h4>{{$t('newsletter.form_title')}}</h4>
        <b-form @submit.stop.prevent="submit" novalidate>

          <b-form-group
              id="email-group"
              label="Email address:"
              label-for="email"
              label-sr-only>

            <b-form-input
                id="email"
                placeholder="Email"
                type="email"
                v-model="$v.email.$model"
                :state="validateState('email')"
                required>
            </b-form-input>
            <b-form-invalid-feedback>
              {{$t('newsletter.validation.email')}}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
              id="name-group"
              :label="$t('newsletter.name')"
              label-for="name"
              label-sr-only>

            <b-form-input
                id="name"
                :placeholder="$t('newsletter.name')"
                type="text"
                v-model="$v.name.$model"
                :state="validateState('name')"
                required>
            </b-form-input>
            <b-form-invalid-feedback>
              {{$t('newsletter.validation.required')}}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
              id="surname-group"
              :label="$t('newsletter.surname')"
              label-for="surname"
              label-sr-only>

            <b-form-input
                id="surname"
                :placeholder="$t('newsletter.surname')"
                type="text"
                v-model="$v.surname.$model"
                :state="validateState('surname')"
                required>
            </b-form-input>
            <b-form-invalid-feedback>
              {{$t('newsletter.validation.required')}}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-checkbox
              name="additional_subscription1"
              v-model="additional_subscription1">
            {{$t('newsletter.' + context + '.extra_subscription_1')}}
          </b-form-checkbox>

          <b-form-checkbox
              name="additional_subscription2"
              v-model="additional_subscription2">
            {{$t('newsletter.' + context + '.extra_subscription_2')}}
          </b-form-checkbox>

          <b-form-checkbox
              class="no-margin"
              name="accept_data"
              v-model="$v.accept_data.$model"
              :state="validateState('accept_data')"
              required>
            {{$t('newsletter.accept_data')}}
          </b-form-checkbox>
          <b-form-invalid-feedback
              :state="validateState('accept_data')">
            {{$t('newsletter.validation.accept_data')}}
          </b-form-invalid-feedback>

          <div v-if="showError" class="error">
            {{$t('newsletter.submission_error')}}
          </div>
          <div v-if="alreadySubscribed" class="error">
            {{$t('newsletter.already_subscribed')}}
          </div>

          <b-button type="submit" variant="dark" :disabled="isProcessing">
            <b-spinner small type='grow' v-if="isProcessing"></b-spinner>
            <span v-html="$t('newsletter.subscribe')"></span>
            <span class="icon" v-html="iconArrow"></span>
          </b-button>

      </b-form>
    </div>

    <transition name="fade">
      <div v-if="submitted" class="success">
        {{$t('newsletter.submission_success')}}
      </div>
    </transition>

  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormInvalidFeedback,
  BButton,
  BSpinner} from 'bootstrap-vue';
import { iconArrow } from '../styles/icons';

import Vue from "vue";
import { VueReCaptcha } from "vue-recaptcha-v3";
import { validationMixin } from 'vuelidate';
import { required, email, sameAs } from "vuelidate/lib/validators";
import {getCurrentLanguage} from "../i18n/i18n";

export default {
  name: 'NewsletterForm',
  components: {
    'b-form': BForm,
    'b-form-group': BFormGroup,
    'b-form-input': BFormInput,
    'b-form-checkbox': BFormCheckbox,
    'b-form-invalid-feedback': BFormInvalidFeedback,
    'b-button': BButton,
    'b-spinner': BSpinner
  },
  props: ['emailProp'],
  mixins: [validationMixin],
  data(){
    return {
      iconArrow: iconArrow,
      email:'',
      name: '',
      surname: '',
      additional_subscription1: false,
      additional_subscription2: false,
      accept_data: false,
      context : 'bosw',
      isProcessing: false,
      submitted: false,
      showError: false,
      alreadySubscribed:false
    }
  },
  validations: {
    name: {
      required
    },
    surname: {
      required
    },
    email: {
      email,
      required
    },
    accept_data: {
      sameAs: sameAs( () => true)
    }
  },
  mounted(){
    this.email = this.emailProp;

    if(document.querySelector('#app').classList.contains('bosa'))
      this.context = 'bosa';
    else if(document.querySelector('#app').classList.contains('boss'))
      this.context = 'boss';

    console.log(this.context);
    if(this.$recaptchaSiteKey){
      Vue.use(VueReCaptcha, { siteKey: this.$recaptchaSiteKey });
    }
  },
  methods:{
    submit(){

      this.isProcessing = true;
      this.showError = false;
      this.alreadySubscribed = false;

      // Validate form and prevent submission if there's any error
      this.$v.$touch();
      if (this.$v.$anyError) {
        this.isProcessing = false;
        return;
      }

      // Submit form after getting a recaptcha token
      this.$recaptcha("submit").then(async (token) => {

        const body = document.querySelector('body');
        const csrf = body.dataset.csrf;

        const lang = getCurrentLanguage();

        let extraSubscriptions = [];
        if(this.context === 'bosw'){
          if(this.additional_subscription1)
            extraSubscriptions.push('bosa');
          if(this.additional_subscription2)
            extraSubscriptions.push('boss');
        }
        else if(this.context === 'bosa'){
          if(this.additional_subscription1)
            extraSubscriptions.push('bosw');
          if(this.additional_subscription2)
            extraSubscriptions.push('boss');
        }
        else if(this.context === 'boss'){
          if(this.additional_subscription1)
            extraSubscriptions.push('bosw');
          if(this.additional_subscription2)
            extraSubscriptions.push('bosa');
        }

        const extraSubscriptionsString = extraSubscriptions.join(',');

        const parameters = `?csrf=${csrf}&token=${token}&firstName=${this.name}&lastName=${this.surname}&email=${this.email}&site=${this.context}&extraSubscriptions=${extraSubscriptionsString}&lang=${lang}`;

        // Send form to submit endpoint
        let api = `${process.env.VUE_APP_HOST}/.rest/newsletter/v1/submit-form${parameters}`;

        let formResponse = await fetch(api).then((response) => {

          // Throw an error (that will be detected by the catch) if something went wrong with the request
          if (response.status >= 400 && response.status < 600) {

            // Error 429 = User has already been subscribed
            if(response.status === 429){
              this.alreadySubscribed = true;
              this.isProcessing = false;
              return;
            }
            else{
              throw new Error("Bad response from server");
            }
          }

          return response;

        }).catch(() => {
          // Show generic error message on the form
          this.showError = true;
          this.isProcessing = false;
          return;
        });

        if(formResponse){
          if (formResponse.ok){
            this.submitted = true;
          }
          else{
            // If the request worked but the operation on the server was unsuccessful
            // Show error message on the form
            this.showError = true;
          }
          this.isProcessing = false;
        }
      }).catch(() => {
        // Show generic error message on the form
        this.showError = true;
        this.isProcessing = false;
        return;
      });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
    }
  }
};
</script>
<style scoped lang="scss">
  .form-wrapper{
    min-height:100%;
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    color: $black;
    font-size:1rem;
    font-style: normal;

    .success{
      text-transform: uppercase;
      font-style:italic;
      font-weight:700;
      font-size:1.5rem;
    }

    .form-container{
      width:100%;
      max-width:300px;

      h4{
        text-transform: uppercase;
        font-style:italic;
        font-weight:700;
      }

      .error{
        margin-top:$spacer/1.5;
        font-style:italic;
      }

      .form-group{
        margin-bottom: $spacer/1.5;
      }

      .custom-control{
        margin-bottom: $spacer/2;

        /deep/ .custom-control-input{
          top:calc(50% - (1.25rem / 2));

          &.is-invalid ~ .custom-control-label,
          &.is-valid ~ .custom-control-label
          {
            color: inherit;
          }

          &.is-valid ~ .custom-control-label::before{
            background-color: $white;
            border-color:$white;
          }
        }

        /deep/ .custom-control-label{
          &::before,
          &::after{
            top:calc(50% - (1.25rem / 2))!important;
          }
        }
      }

      .form-control{
        &.is-valid,
        &.is-invalid{
          background-image:none;
          padding-right:$input-padding-x;
        }
      }

      .invalid-feedback{
        color:inherit;
        font-style:italic;
      }

      .btn{
        margin-top:$spacer;

        .spinner-grow{
          margin-right: $btn-padding-x;
        }
      }
    }
  }
</style>