<template>
  <div class="gallery-component" @mouseover="showLinks()" @mouseout="hideLinks()">
    <Headline :headline="headline" class="component-margin"></Headline>
    <div class="gallery-swiper component-margin">
      <div class="swiper-container-parent" >
        <swiper v-if="swiperVisible()" class="swiper" ref="mySwiper" :options="{
          slidesPerView: 'auto',
          spaceBetween: 5,
          freeMode: true,
          loop: true,
          navigation: false
        }">
          <swiper-slide v-for="(image, imageIndex) in getImages(15)" :key="imageIndex + image.image" class="images-wrapper" :style="'width:' + image.width + '%;'">
            <div>
              <img
                  class="image"
                  @click="openImageFullscreen(image.image)"
                  :style="{'max-height': height + 'rem'}"
                  :data-url="image.image"
                  :src="image.image">
            </div>
          </swiper-slide>
        </swiper>
        <div class="gallery-links" ref="galleryLinks">
          <div class="gallery-open-link" @click="openGallery()">
            {{ openGalleryLabel }}
            <span class="icon arrow-down" v-html="iconArrow"></span>
          </div>
          <a v-if="openFlickerLink" :href="openFlickerLink" target="_blank" class="flicker-open-link">
            <i class="icofont-brand-flikr"></i>
            <span class="flickr-open-label" v-html="formattedOpenFlickerLabel"></span>
          </a>
        </div>
      </div>
    </div>

    <Overlay ref="galleryOverlay"
             :background-color="'white'"
             class="gallery-image-list"
             v-on:overlay-opened="isFullscreenGalleryOpen = true"
             v-on:overlay-closed="isFullscreenGalleryOpen = false"
             :button-color="'white'">
      <template v-for="(row, rowIndex) in arrangeImages()">
          <div v-lazyload v-for="(image, imageIndex) in row" :key="imageIndex + image.image + rowIndex">
            <img
                v-if="isFullscreenGalleryOpen"
                :data-refresh="refresh"
                class="image lazy-img-fadein"
                :style="'height:' + image.height + 'rem;' + image.margin"
                @click="openImageFullscreen(image.image)"
                :data-url="image.image"
                :src="image.image">
            <span style="display: none;" class="icon open-image-icon" v-html="iconSearch"></span>
          </div>
      </template>
    </Overlay>

    <Overlay ref="fullscreenImageOverlay"
             :background-color="'black'"
             class="gallery-image-fullscreen"
             v-on:overlay-opened="isFullscreenImageOpen = true"
             v-on:overlay-closed="isFullscreenImageOpen = false"
             :button-color="'white'">
      <img v-if="isFullscreenImageOpen" :src="fullscreenCurrentImage" >
      <span class="icon navigation-arrows arrow-left" v-html="iconArrow" @click="navigateGallery(-1)"></span>
      <span class="icon navigation-arrows arrow-right" v-html="iconArrow" @click="navigateGallery(1)"></span>
    </Overlay>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/swiper-bundle.css'
import {iconArrow, iconSearch} from "../styles/icons";
import Headline from "./Headline";
import Overlay from "./Overlay";

let allImages = [];

export default {
  name: 'FlickrGallery',
  props: ['images', 'albumLink', 'headline', 'openGalleryLabel', 'openFlickerLabel', 'openFlickerLink'],
  components: {
    Swiper,
    SwiperSlide,
    Overlay,
    Headline
  },
  data() {
    return {
      placeholder: `${process.env.VUE_APP_HOST}${process.env.VUE_APP_PLACEHOLDER_IMAGE}`,
      iconArrow: iconArrow,
      iconSearch: iconSearch,
      fullscreenCurrentImage: '',
      fullscreenImageIndex: 0,
      isFullscreenImageOpen: false,
      isFullscreenGalleryOpen: false,
      height: 26,
      refresh: 0
    }
  },
  computed:{
    formattedOpenFlickerLabel(){
      return this.openFlickerLabel.split(' ').join('<br>');
    }
  },
  mounted() {
    if (window.innerWidth < 767){
      this.height = 8;
    } else if (window.innerWidth < 991) {
      this.height = 15;
    }

    window.addEventListener('resize', () => {
      this.refresh = Math.random();
    });
  },
  methods: {
    swiperVisible() {
      return (!this.isFullscreenImageOpen && !this.isFullscreenGalleryOpen);
    },
    openGallery() {
      this.$refs.galleryOverlay.toggle();
    },
    navigateGallery(direction) {
      this.fullscreenImageIndex += direction;

      if (this.fullscreenImageIndex < 0) {
        this.fullscreenImageIndex = allImages.length - 1;
      } else if (this.fullscreenImageIndex >= allImages.length) {
        this.fullscreenImageIndex = 0;
      }

      this.fullscreenCurrentImage = allImages[this.fullscreenImageIndex];
    },
    openImageFullscreen(image) {
      this.$refs.fullscreenImageOverlay.toggle();
      setTimeout(() => {
        if (allImages.length === 0) {
          allImages = Object.keys(this.images).map(k => this.images[k]).filter(i => typeof i === 'string' && i.startsWith('https://'));
          allImages = allImages.map(i => i.split("$")[0].replace('_o.', '_b.'));
        }

        const imageLink = allImages.filter(i => i.startsWith(image.slice(0, -4)))[0];
        const fullscreenImageIndex = allImages.indexOf(imageLink);
        this.fullscreenImageIndex = fullscreenImageIndex;
        this.fullscreenCurrentImage = imageLink;
      }, 0);
    },
    showLinks() {
      this.$refs.galleryLinks.style.visibility = 'visible';
      this.$refs.galleryLinks.style.opacity = '1';
    },
    hideLinks() {
      this.$refs.galleryLinks.style.visibility = 'hidden';
      this.$refs.galleryLinks.style.opacity = '0';
    },
    getImageStyle() {
      const width = 50000 / window.innerWidth;
      return 'width: ' + width + '%;';
    },
    getImages(limit) {
      let keys = Object.keys(this.images);
      if (limit) {
        keys = keys.slice(0, 30)
      }
      const imageList = [];
      keys.forEach(k => {
            if (this.images[k].split) {
              imageList.push({
                image: this.images[k].split("$")[0].replace('_o.', '.'),
                width: this.getImageWidth(this.images[k])
              });
            }
          }
      );
      return imageList;
    },
    getImageWidth(image) {
      const imageInfo = image.split("$");
      const ratio = imageInfo[1] / imageInfo[2];
      return (this.height * 16 * ratio * 100) / window.innerWidth;
    },
    getSrcSet(image) {
      return [
          image.replace('_o.', '.') + ' 480w',
          image.replace('_o.', '_b.') + ' 967w'
      ];
    },
    arrangeImages() {
      const galleryWidth = window.innerWidth - 2;
      const maxScreen = galleryWidth * 1.4;
      const imageList = Object.keys(this.images).map(k => this.images[k]).filter(i => typeof i === 'string');
      const imagesWithInfo = imageList.map(image => {
        const imageInfo = image.split("$");
        const ratio = imageInfo[1] / imageInfo[2];
        const width = this.height * 16 * ratio;

        return {
          image: imageInfo[0].replace('_o.', '.'),
          width: width
        }
      });
      let currentRow = [];
      let currentWidth = 0;

      const imagesInRows = imagesWithInfo.map(image => {
        if (currentWidth + image.width < maxScreen) {
          currentRow.push(image);
          currentWidth += image.width;
        } else {
          const row = [...currentRow];
          const calculatedHeight = (galleryWidth - (6.87 * (row.length - 1))) / currentWidth;
          row.forEach(i => {
            i.height = this.height * calculatedHeight;
            i.margin = '';
          })
          if (row.length > 0) {
            row[row.length - 1].margin = 'margin-right: 0;';
          }
          currentRow = [];
          currentWidth = 0;
          return row;
        }

      })

      return imagesInRows;
    }
  }
}
</script>
<style lang="scss" scoped>
  .gallery-component {
    //height: 42rem;
    padding-bottom: $spacer;

    .gallery-swiper {
      height: 26rem;

      &.component-margin{
        margin-top:$spacer*1.5;
      }

      .swiper-container-parent {
        position: absolute;
        height: 26rem;
        left: 0;
        right: 0;
        .swiper-container, .swiper-slide {
          height: 100%;
        }
      }
    }
  }

  .images-wrapper {
    img {
      cursor: pointer;
      max-width: 100%;
      height: 100%;
    }
    div {
      height: 100%;
    }
  }

  .gallery-links {
    position: relative;
    visibility: hidden;
    transition: opacity 0.3s ease-in;

    .gallery-open-link {
      margin-top: $spacer / 2;
      font-size: 1.5rem;
      text-transform: uppercase;
      font-weight: bold;
      text-align: center;
      cursor: pointer;
      span {
        display: block;
      }
    }

    .flicker-open-link{
      position: absolute;
      right: $spacer;
      top: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 0.8rem;
      font-weight: bold;
      text-transform: uppercase;
      line-height: 0.7rem;
      cursor: pointer;
      border:none;

      i {
        flex-shrink: 1;
        border: 1px solid $black;
        padding: 0.2rem;
        font-size: 1.4rem;
        border-radius: 5px;
        margin-right: $spacer / 6;
      }
    }
  }

  .gallery-image-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
    left: 0;

    &::-webkit-scrollbar {
      width: 1px;
    }

    /deep/ button {
      position: fixed!important;
      margin-top: $spacer / 2;
      z-index: 10000;
    }

    div {
      position: relative;
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;

      &:hover {
        .open-image-icon {
          display: block!important;
        }
      }

      .open-image-icon {
        position: absolute;
        color: white;
        z-index: 10000;
        cursor: pointer;
        pointer-events: none;

        /deep/ svg {
          height: rem(80px);
          width: rem(80px);
        }
      }
    }

    img {
      width:auto!important;
      height: 26rem;
      margin-right: $spacer / 6;
      margin-bottom: $spacer / 6;
      cursor: pointer;

      &:hover {
        transform: scale(0.98);
        transition: all .05s ease-in;
      }
    }
  }

  .gallery-image-fullscreen {
    padding: 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;

    /deep/ button {
      z-index: 10000;
      top: $spacer / 2
    }

    img {
      height: 100%;
    }

    .navigation-arrows {
      position: absolute;
      color: white;
      cursor: pointer;

      &:hover {
        transform: scale(1.1);
        transition: all .05s ease-in;
      }
    }

    .arrow-left {
      left: $spacer;
    }

    .arrow-right {
      right: $spacer;
    }
  }

  @include media-breakpoint-down(md) {
    .gallery-component {
      .gallery-swiper {
        height: 15rem;

        &.component-margin{
          margin-top:$spacer;
        }

        .swiper-container-parent {
          height: 15rem;
        }
      }

      .gallery-links {
        visibility: visible;

        .gallery-open-link {
          margin-top: $spacer / 5;
          font-size: 0.7rem;
          span {
            /deep/ svg {
              height: rem(30px);
            }
          }
        }
        .flicker-open-link{
          font-size: 0.6rem;
          line-height: 0.6rem;

          i {
            font-size: 1rem;
            border-radius: 4px;
          }
        }
      }

      .gallery-image-fullscreen {
        img {
          height: auto;
          width: 100%;
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .gallery-component {
      .gallery-swiper {
        height: 8rem;

        .swiper-container-parent {
          height: 8rem;
        }
      }

      .gallery-links {
        .gallery-open-link {
          font-size: 0.5rem;
          span {
            /deep/ svg {
              height: rem(20px);
            }
          }
        }

        .flicker-open-link{
          right: $spacer / 2;
          font-size: 0.4rem;
          line-height: 0.4rem;

          i {
            font-size: 0.7rem;
            border-radius: 4px;
          }

          .flickr-open-label{
            max-width:$spacer*2;
            display:inline-block;
            white-space:nowrap;
            overflow:hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
</style>