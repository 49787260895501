<template>
  <div class="wrapper">
    <div class="accordion accordion-container" role="tablist">
      <Headline v-if="headline" :headline="headline"></Headline>
      <div no-body class="accordion" v-for="(accordion) in this.accordion" :key="accordion['@id']">
        <h2 header-tag="header" role="tab">
          <div v-b-toggle="'accordion-' + accordion['@id']" name="accordion">
            <span class="toggle-icons">
              <span class="icon open" v-html="iconClose"></span>
              <span class="icon closed" v-html="iconArrowDown"></span>
            </span>
            <div class="accordion-title">
              <strong>{{ accordion.title.trim() }}</strong>
            </div>
          </div>
        </h2>
        <b-collapse :visible="visible" class="accordion-content" :id="'accordion-' + accordion['@id']" role="tabpanel">
          <EditableArea v-if="accordion.accordionContent" v-bind:content="accordion.accordionContent" />
        </b-collapse>
      </div>
    </div>
  </div>
</template>

<script>
  import { inEditorEdit, EditableArea } from '../vue-editor';
  import { iconArrowDown, iconClose} from "../styles/icons";
  import Headline from './Headline';

  export default {
    name: 'Accordion',
    props: ['headline', 'accordion', 'accordionContent'],
    components: {
      Headline,
      EditableArea
    },
    data() {
      return {
        visible: inEditorEdit,
        iconArrowDown: iconArrowDown,
        iconClose: iconClose
      }
    }
  }
</script>

<style scoped lang="scss">

  .wrapper{
    display:flex;
    flex-direction:column;
    align-items:center;
  }

  .accordion-container {
    width:100%;
    max-width:1150px;
    padding:0 $spacer;

    /deep/ .headline{
      margin-bottom:$spacer*1.5 !important;
    }

    .accordion {
      margin: auto;
      margin-bottom: $spacer/1.5;
    }

    .accordion-title {
      display: inline-block;
      font-style: italic;
    }
  }

  .collapsed {
    .open {
      display: none;
    }
  }

  .not-collapsed {
    .closed {
      display: none;
    }
  }

  .accordion {
    width: 100%;
    text-align: center;

    h2 {
      text-transform: uppercase;

      & > div {
        display: flex;
        flex-direction: row;
        flex-shrink: 1;
      }
    }

    .toggle-icons {
      display: inline-block;
      padding-right:$spacer;
      font-weight: bold;

      /deep/ svg{
        height: rem(36px);
        width: rem(36px);
        margin-top: rem(-5px);
      }
    }

    .accordion-content {
      display: block;
      margin-left: $spacer + rem(36px);
    }

    div {
      text-align: left;
    }
  }

  @include media-breakpoint-down(md){
    .accordion-container {
      padding: 0 $spacer*5;

      & > div {
        margin: auto;
        margin-bottom: $spacer/2;
      }

      .accordion{
        .accordion-content {
          margin-left: $spacer/2 + rem(28px);
        }

        .toggle-icons {
          padding-right:$spacer/2;

          /deep/ svg{
            height: rem(28px);
            width: rem(28px);
            margin-top: rem(-5px);
          }
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .accordion-container {
      padding: 0 $spacer;

      /deep/ .headline{
        margin-bottom:$spacer/2 !important;
      }

      & > div {
        margin-bottom: $spacer/4;
      }

      h2{
        margin-bottom:$spacer/4;
      }

      .accordion{
        .accordion-content {
          margin-left: $spacer/2 + rem(22px);
        }

        .toggle-icons {
          /deep/ svg{
            height: rem(22px);
            width: rem(22px);
          }
        }
      }
    }
  }
</style>