<template>
  <b-sidebar
      id="hamburger-menu"
      title="Menu"
      text-variant="light"
      no-header
      right
      backdrop
      width="500px">
    <b-nav vertical class="w-100">
      <HamburgerMenuItem
          v-for="item in hamburgerNavigation"
          :key="item['@id']"
          :item="item"/>
    </b-nav>
  </b-sidebar>
</template>

<script>
import { BSidebar } from 'bootstrap-vue';
import HamburgerMenuItem from './HamburgerMenuItem';

export default {
  name: 'HamburgerMenu',
  props: ['hamburgerNavigation', 'isHomePage'],
  components:{
    'b-sidebar': BSidebar,
    HamburgerMenuItem
  }
}
</script>
<style lang="scss">
  .b-sidebar{

    .b-sidebar-body{
      padding-top:$spacer*1.5;
    }

    &.bg-light{
      background-color:var(--accent-color) !important;
    }

    &.text-light{
      color:$hamburger-menu-color !important;
    }
  }
</style>