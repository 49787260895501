<template>
  <div class="wrapper">
    <Headline :headline="headline"></Headline>
    <div class="cta-list component-margin">

        <AdvancedLink :link="cta.advancedLink" :class="'cta-container ' + getCTAStyle(cta)" v-for="(cta) in ctas" :key="cta['id']">
          <span v-if="cta.advancedLink.type && cta.advancedLink.type !== 'none'" class="icon arrow-right" v-html="iconArrow"></span>
          <span v-else class="icon" v-html="iconInactiveCTA"></span>
          <div class="cta-info">
            <span class="cta-title h1">{{ cta.title }}</span>
            <span class="cta-subtitle h6">{{ cta.subtitle }} </span>
          </div>
        </AdvancedLink>

    </div>
  </div>
</template>

<script>
  import {iconArrow, iconInactiveCTA} from '../styles/icons';
  import Headline from "./Headline";
  import AdvancedLink from "./AdvancedLink";

  export default {
    name: 'CTAList',
    props: ['headline', 'ctas'],
    components: {
      Headline,
      AdvancedLink,
    },
    data() {
      return {
        iconArrow: iconArrow,
        iconInactiveCTA: iconInactiveCTA
      };
    },
    methods: {
      getCTAStyle(cta) {
        if (!cta.advancedLink.type || cta.advancedLink.type === 'none') {
          return 'disabled-cta';
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  .wrapper {
    display:flex;
    flex-direction:column;
    align-items:center;
    margin-bottom: $spacer / 2;

    /deep/ .headline{
      color:var(--accent-color);
    }
  }

  .cta-list {
    width:100%;
    max-width:1150px;
    margin-left:-$spacer;
    margin-right:-$spacer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap:wrap;
    color: var(--accent-color);
    text-transform: uppercase;

    .cta-container {
      max-width: calc(25% - #{$spacer/2});
      text-align: left;
      padding: $spacer/1.5;
      margin:$spacer/2 0;
      border: 1px solid transparent;
      transition:$transition-base;
      display: flex;
      flex-direction: row;
      justify-content: start;

      &.disabled-cta {
        color: $black!important;
        cursor: default;
      }

      &:hover,
      &:focus{
        border: 1px solid;
      }

      .cta-info {
        margin-left: $spacer/4;

        span {
          display:block;
          font-style:normal;
          font-weight: 900 !important;
          hyphens:auto;
          margin-bottom:0;

          &.h1{
            line-height:1;
            margin-bottom:$spacer/12;
          }

          &.h6{
            line-height:1.5;
            font-size:$smaller-font-size;
          }
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    .cta-list {
      width: 100%;
      margin-left:-$spacer/4;
      margin-right:-$spacer/4;

      .cta-container {
        max-width: calc(25% - #{$spacer/6});
        margin:$spacer/2 0;
        padding:$spacer/3;

        .arrow-right {
          /deep/svg {
            margin-top: rem(-8px);
            height: rem(23px);
            width: rem(23px);
          }
        }

        .cta-info {
          margin-left: $spacer/8;
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .cta-list {
      .cta-container {
        max-width: calc(50% - #{$spacer/6});
        margin:$spacer/4 0;
      }
    }
  }
</style>