<template>
  <div class="component-margin " :class="{'no-margin': noMargin}">
    <h1 :class="'headline ' + type">{{ headline }}</h1>
  </div>
</template>

<script>
 export default {
   name: 'Headline',
   props: ['type', 'headline', 'hasMargin'],
   computed:{
     noMargin(){
       return this.hasMargin !== 'undefined' && this.hasMargin === false;
     }
   }
 }
</script>

<style scoped lang="scss">
  div {
    display:flex;
    flex-direction:column;
    align-items:center;
  }

  .component-margin{
    margin-top:$spacer*3 !important;
  }

  .headline {
    color: $black;
    font-style: italic;
    font-weight: 300!important;
    text-transform: uppercase;
    text-align: center;
    line-height:1;
    display: inline-block;
    border: 1.5px solid;
    padding: $spacer/6 $spacer/3 $spacer/10 $spacer/3;
    margin-bottom:0;

    &.inverse {
      color: $white;
      background-color: $black;
    }

    &.shrinked {
      padding: $spacer/5 $spacer/2.5 $spacer/8.5 $spacer/2.5;
      font-size: rem(29px);
    }

    &.accent {
      background-color: var(--accent-color);
    }
  }

  @include media-breakpoint-down(md) {
    .component-margin{
      margin-top:$spacer*2 !important;
    }
  }

  @include media-breakpoint-down(sm) {
    .component-margin{
      margin-top:$spacer*1.5 !important;
    }
  }
</style>