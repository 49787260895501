<template>
  <div class="wrapper component-margin">

    <div class="newsletter">
      <b-form-input placeholder="Email" type="email" :size="size" v-model="email"></b-form-input>

      <b-button squared
        :variant="variant"
        :size="size"
        @click="toggleOverlay">
        <span v-html="$t('newsletter.subscribe').replace(' ', '<br>')"></span>
        <span class="icon" v-html="iconArrow"></span>
      </b-button>

    </div>

    <SocialLinks/>

    <Overlay
      ref="overlay"
      :background-color="'var(--accent-color)'">

      <NewsletterForm :emailProp="email"/>

    </Overlay>
  </div>
</template>

<script>
import {
  BFormInput,
  BButton }
  from 'bootstrap-vue';
import NewsletterForm from "./NewsletterForm";
import SocialLinks from "./SocialLinks";
import Overlay from "./Overlay";
import { iconArrow } from '../styles/icons';

export default {
  name: 'Newsletter',
  components: {
    NewsletterForm,
    SocialLinks,
    Overlay,
    'b-form-input': BFormInput,
    'b-button': BButton
  },
  props: ['size'],
  data(){
    return {
      variant: 'outline-primary',
      iconArrow: iconArrow,
      email: ''
    }
  },
  mounted(){
    if(document.querySelector('#app').classList.contains('bosa'))
      this.variant = 'outline-secondary';
    else if(document.querySelector('#app').classList.contains('boss'))
      this.variant = 'outline-success';
  },
  methods:{
    toggleOverlay(){
      this.$refs.overlay.toggle();
    }
  }
};
</script>
<style scoped lang="scss">

  .wrapper{
    display:flex;
    flex-direction:column;
    align-items:center;
  }

  footer .wrapper{
    align-items:flex-start;
  }

  footer .component-margin{
    margin:0 !important;
  }

  .newsletter {
    display:flex;
    flex-direction:row;
    width:420px;
    max-width:100%;
    padding-bottom:$spacer;

    &:hover{
      button{
        background-color: var(--accent-color);
        color: $white;
      }
    }

    input[type=text],
    input[type=email]{
      width:auto;
      border: 1.5px solid var(--accent-color);
      font-size: 1rem;
      flex-grow: 1;
      background-color:inherit;

      &::placeholder{
        color: var(--accent-color);
        text-transform: uppercase;
      }
    }

    .btn{
      padding:0;
      padding-left:$spacer/6;
      padding-right:$spacer/8;
      border:0;
      font-size:0.95rem;
      font-weight:500 !important;
      line-height:1.15;
      margin-right:.4rem;

      &:not(:disabled):not(.disabled){
        &:hover{
          background-color: var(--accent-color);
        }

        &:focus{
          background-color: var(--accent-color);
          color: $white;
          box-shadow: $focus-shadow !important;
        }

        &:active{
          background-color: var(--accent-color);
        }
      }

      span.icon {
        padding-left:$spacer/8;
      }
    }
  }

  footer .newsletter{
    width:350px;
    max-width:100%;
    padding-top: $spacer;
    padding-left: $spacer;

    input[type=text],
    input[type=email]{
      border: 1px solid $footer-color-lighter !important;
      color: $footer-color;
      font-size: 0.7rem !important;

      &::placeholder{
        color: $footer-color;
        transition: color 0.15s ease-in-out;
      }
    }

    .btn{
      color: $footer-color-lighter;
      font-size: 0.7rem;
      font-weight:400 !important;

      span{
        /deep/ svg{
          height: rem(12px);
          width: rem(12px);
        }
      }

      &:focus,
      &:active{
        color: $black !important;
      }
    }

    &:hover{
      input[type=text],
      input[type=email]{
        border-color:var(--accent-color) !important;

        &::placeholder{
          color: var(--accent-color);
        }
      }

      button{
        color: $black;
      }
    }
  }

  @include media-breakpoint-down(md){
    .newsletter{
      max-width:500px;
    }
  }

  @include media-breakpoint-down(sm){
    .newsletter{
      max-width:320px;

      input[type=text],
      input[type=email]{
        font-size: 0.8rem;
      }

      .btn{
        font-size:0.8rem;

        span.icon {
          padding-left:$spacer/8;
        }
      }
    }
  }
</style>