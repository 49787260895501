export const iconClose = `<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 10.583 10.583">
  <path fill="currentColor" d="M10.46 2.365L2.386 10.44.142 8.197 8.218.122z"/>
  <path fill="currentColor" d="M2.385.124L10.461 8.2l-2.244 2.243L.142 2.367z"/>
</svg>`

export const iconArrow =`<svg xmlns="http://www.w3.org/2000/svg" width="29" height="40" viewBox="0 0 7.673 10.583">
  <path fill="currentColor" d="M.11 8.133l2.288 2.288 2.974-2.974-2.288-2.288z"/>
  <path fill="currentColor" d="M2.395.098L.107 2.385 3.081 5.36 5.37 3.072z"/>
  <path fill="currentColor" d="M5.27 2.973L2.982 5.26 5.27 7.548 7.558 5.26z"/>
</svg>`

export const iconArrowDown =`<svg viewBox="0 0 41.279 29.743" xmlns="http://www.w3.org/2000/svg">
  <path d="M9.11 0L.006 9.104 11.84 20.94l9.104-9.105z" fill="currentColor"/>
  <path d="M41.28 9.104L32.176 0 20.34 11.835l9.105 9.104z" fill="currentColor"/>
  <path d="M29.75 20.639l-9.104-9.104-9.104 9.104 9.104 9.104z" fill="currentColor"/>
  <path d="M9.11 0L.006 9.104 11.84 20.94l9.104-9.105z" fill="currentColor"/>
  <path d="M41.28 9.104L32.176 0 20.34 11.835l9.105 9.104z" fill="currentColor"/>
  <path d="M29.75 20.639l-9.104-9.104-9.104 9.104 9.104 9.104z" fill="currentColor"/>
  <path d="M9.11 0L.006 9.104 11.84 20.94l9.104-9.105z" fill="currentColor"/>
  <path d="M41.28 9.104L32.176 0 20.34 11.835l9.105 9.104z" fill="currentColor"/>
  <path d="M29.75 20.639l-9.104-9.104-9.104 9.104 9.104 9.104z" fill="currentColor"/>
</svg>`

export const iconSearch = `<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 10.583 10.583">
  <g fill="currentColor">
    <path d="M4.908 2.192H3.722v1.541H2.18V4.92h1.542v1.542h1.186V4.919H6.45V3.733H4.908z"/>
    <path d="M1.292 4.326a3.026 3.026 0 013.022-3.023c1.667 0 3.023 1.356 3.023 3.023S5.981 7.35 4.314 7.35a3.026 3.026 0 01-3.022-3.023m6.51 2.29a4.15 4.15 0 00.687-2.29A4.18 4.18 0 004.314.152 4.18 4.18 0 00.14 4.326 4.18 4.18 0 004.314 8.5a4.15 4.15 0 002.29-.686L9.26 10.47l1.199-1.198z"/>
  </g>
</svg>`;

export const timelineBadge = `<svg xmlns="http://www.w3.org/2000/svg" width="219.02" height="302" viewBox="0 0 57.949 79.904">
  <defs>
    <clipPath id="a">
      <path d="M0 14400h4000V0H0z"/>
    </clipPath>
  </defs>
  <g clip-path="url(#a)" transform="matrix(.35278 0 0 -.35278 -632.772 4599.477)">
    <path fill="currentColor" d="M1949.415 12866.242l-56.832-48.95c-8.217-7.078-20.329-7.24-28.734-.385l-60.452 49.317a22.364 22.364 0 00-8.228 17.33v130.95c0 12.352 10.013 22.365 22.364 22.365h117.286c12.351 0 22.364-10.013 22.364-22.364v-131.315c0-6.51-2.837-12.697-7.769-16.945"/>
  </g>
</svg>`;

export const iconInactiveCTA = `<svg width="17.856" height="40" viewBox="0 0 4.724 10.583" xmlns="http://www.w3.org/2000/svg">
  <path d="M0 10.539l4.622.044.058-6.008L.058 4.53z" fill="currentColor"/>
  <path d="M.102 0L.057 4.622l4.623.045.044-4.622z" fill="currentColor"/>
</svg>`;

export const linkedin = `<svg xmlns="http://www.w3.org/2000/svg" width="39.757" height="40" viewBox="0 0 10.519 10.583">
  <defs>
    <clipPath id="a">
      <path d="M0 595.276h841.89V0H0z"/>
    </clipPath>
  </defs>
  <g clip-path="url(#a)" transform="matrix(.21876 0 0 -.21876 -51.214 43.96)">
    <path fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="2" d="M235.1 196.64c0 1.834 1.525 3.322 3.406 3.322h39.274c1.881 0 3.406-1.488 3.406-3.322v-39.734c0-1.834-1.525-3.322-3.406-3.322h-39.274c-1.881 0-3.406 1.488-3.406 3.322v39.735z"/>
    <path fill="currentColor" d="M252.93 161.14h6.96v11.694c0 .625.046 1.251.229 1.698.504 1.251 1.649 2.546 3.572 2.546 2.518 0 3.526-1.92 3.526-4.736V161.14h6.96v12.007c0 6.433-3.433 9.426-8.013 9.426-3.754 0-5.403-2.099-6.319-3.528h.046v3.036h-6.96c.09-1.964-.001-20.942-.001-20.942zm-7.332 23.801c2.427 0 3.938 1.608 3.938 3.618-.046 2.055-1.511 3.618-3.892 3.618-2.382 0-3.938-1.563-3.938-3.618 0-2.01 1.51-3.618 3.847-3.618zm3.479-23.801v20.942h-6.96V161.14h6.961z"/>
  </g>
</svg>`;