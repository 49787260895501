<template>
    <div class="hero-cta">

      <div class="previous" @click="clickNavigate('previous')"></div>

      <AdvancedLink
          :link="item.advancedLink"
          :class="{hyphenate: isTitleOverflown}"
          class="title"
          ref="title">
        {{item.title}}
      </AdvancedLink>

      <div
          :class="{hyphenate: isSubtitleOverflown}"
          class="subtitle"
          ref="subtitle">
        {{item.subtitle}}
      </div>

      <div class="next" @click="clickNavigate('next')"></div>

    </div>
</template>

<script>
  import AdvancedLink from "./AdvancedLink";
  import {isOverflownWidth} from "../helpers/AppHelpers";
  export default {
    name: 'HeroCTAItem',
    props: ['item'],
    components:{
      AdvancedLink
    },
    data(){
      return{
        isTitleOverflown: false,
        isSubtitleOverflown: false
      }
    },
    methods:{
      clickNavigate(direction){
        // The parent component listens to this event to handle the navigation
        this.$emit('cta-navigate', direction);
      },
      setHyphenation(){
        if(this.$refs.title.$el)
          this.isTitleOverflown = isOverflownWidth(this.$refs.title.$el);

        if(this.$refs.subtitle)
            this.isSubtitleOverflown = isOverflownWidth(this.$refs.subtitle);
      }
    }
  };
</script>
<style scoped lang="scss">
  $cta-width:rem(300px);

  .hero-cta{
    visibility:hidden;
    position:relative;
    background-color:$white;
    padding:$spacer/2;
    margin:rem(2px);
    width:$cta-width;
    text-transform: uppercase;
    font-weight:400;
    transition:width 0.3s ease-out;

    .previous,
    .next{
      display:none;
      opacity:0;
      border:1px solid red;
      width:40px;
      height:100%;
      position:absolute;
      top:0;
      cursor:pointer;
    }

    .previous{
      left:-40px;
    }

    .next{
      right:-40px;
    }

    &.initialized{
      padding:0;
      color:transparent;
      visibility: visible;
    }

    &.active{
      width:$cta-width;
      color:$black;
      padding:$spacer/2;
      z-index:3;
      transition:width 0.3s ease-out, color 0.2s 0.25s ease-out;

      .previous,
      .next{
        display:block;
      }
    }

    .title{
      font-size:2rem;
      line-height:1;
      border:none;
      position:relative;
    }

    .subtitle{
      font-size:$smaller-font-size;
    }

    .subtitle,
    .title{
      max-width:100%;
      display:block;
    }
  }
  @include media-breakpoint-down(md){
    $cta-width:rem(220px);

    .hero-cta{
      width:$cta-width;

      &.active{
        width:$cta-width;
      }

      .title{
        font-size:1.7rem;
      }
    }
  }

  @include media-breakpoint-down(sm){
    $cta-width:rem(170px);

    .hero-cta{
      padding:$spacer/3;
      width:$cta-width;

      &.active{
        padding:$spacer/3;
        width:$cta-width;
      }

      .title{
        font-size:1.2rem;

        &::after{
          bottom:0;
        }
      }

      .subtitle{
        font-size:$smaller-font-size*0.7;
      }
    }
  }
</style>