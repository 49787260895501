<template>
  <div class="wrapper component-margin">
    <div class="video-container" ref="videoContainer">
      <video ref="videoElement"
             class="video-player"
             :src="src"
             muted loop controls></video>
      <div class="description" ref="videoDescription">
        <div>
          <div class="video-title">{{ title }}</div>
          <div class="video-description" v-html="description"></div>
        </div>

        <a v-if="channelUrl" :href="channelUrl" target="_blank" class="channel-link">
          <i class="youtube-icon icofont-youtube-play"></i>
          <h6 class="youtube-text" v-html="formattedYoutubeChannelLabel"></h6>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import {getHost} from "../helpers/AppHelpers";

export default {
  name: 'VideoComponent',
  props:['channelUrl', 'description', 'title', 'videoFile', 'youtubeChannelLabel'],
  computed:{
    src(){
      return getHost() + this.videoFile['@link'];
    },
    formattedYoutubeChannelLabel(){
      if(this.youtubeChannelLabel)
        return this.youtubeChannelLabel.split(' ').join('<br>');
      else
        return '';
    }
  },
  mounted() {
    document.addEventListener('scroll', this.onScroll);
  },
  destroyed(){
    document.removeEventListener('scroll', this.onScroll);
  },
  methods: {
    onScroll() {
      let video = this.$refs.videoElement;
      if (this.isInViewport(video)) {
        video.play()
      } else {
        if (!video.isPaused) {
          video.pause();
        }
      }
    },
    isInViewport(el) {
      if (el) {
        const rect = el.getBoundingClientRect();
        return (rect.top < window.innerHeight && rect.bottom >= 0);
      }
      return false;
    }
  }
};
</script>

<style scoped lang="scss">
  .wrapper {
    display:flex;
    flex-direction:column;
    align-items:center;
  }

  .video-container {
    width:100%;
    display:flex;
    flex-direction:column;

    video {
      width: 100%;
    }

    /deep/ .video-player{
      z-index:1;
    }

    .channel-link {
      color: $white;
      padding-left:$spacer;
      display:flex;
      align-items:center;
      border:none;
      transition: transform 0.05s ease-in;

      &:hover {
        transform: scale(1.04);
      }

      .youtube-icon {
        border: 1px solid $white;
        padding: 0.5rem;
        font-size: 1.8rem;
        border-radius: 10px;
        margin-right: $spacer / 3;
      }

      .youtube-text {
        margin-bottom: 0;
        font-style: italic;
        text-transform: uppercase;
      }
    }

    .description {
      color: $white;
      z-index: 0;
      background-color: $black;
      padding:$spacer;
      display:flex;
      justify-content: space-between;
      align-items: flex-start;

      .video-description {
        font-weight: 200;
      }

      .video-title {
        font-size: rem(34px);
        font-style: italic;
        font-weight:500;
        text-transform: uppercase;
      }
    }
  }

  @include media-breakpoint-down(md) {
    .video-container {
      .channel-link {
        padding-left:$spacer/1.5;

        .youtube-icon {
          margin-right: $spacer / 5;
          padding: 0.4rem;
          font-size: 1.2rem;
          border-radius: 7px;
        }

        .youtube-text {
          font-size: rem(11px);
        }
      }

      .description {
        padding:$spacer/1.5;

        .video-description {
          font-size: rem(15px);
        }

        .video-title {
          font-size: rem(17px);
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .video-container {
      margin-top: $spacer / 2;

      .channel-link {
        color: $white;
        padding-left:$spacer/2;

        .youtube-icon {
          margin-right: $spacer / 10;
          padding: 0.2rem;
          font-size: 0.8rem;
          border-radius: 3px;
        }

        .youtube-text {
          font-size: rem(7px);
        }
      }

      .description {
        padding:$spacer/2;

        .video-description {
          font-size: rem(10px);
        }

        .video-title {
          font-size: rem(12px);
        }
      }
    }
  }
</style>