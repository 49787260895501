<template>
  <div class="centered-wrapper">
    <div class="two-cols-wrapper">
      <TwoColsItem
          v-for="section in sections"
          :key="section['@id']"
          :section="section"/>
    </div>
  </div>
</template>

<script>
  import TwoColsItem from "./TwoColsItem";
  export default {
    name: 'TwoCols',
    props: ['sections'],
    components:{
      TwoColsItem
    }
  };
</script>

<style scoped lang="scss">
  .two-cols-wrapper{
    width:100%;
    max-width:1150px;
    padding:$spacer/2 $spacer;
  }

  @include media-breakpoint-down(sm){
    .two-cols-wrapper{
      padding-left:$spacer/2;
      padding-right:$spacer/2;
    }
  }

  @include media-breakpoint-down(xs){
    .two-cols-wrapper{
      .row{
        padding-bottom: $spacer/2;

        /deep/ .title {
          h3{
            margin-bottom:$spacer/4;
          }
        }
      }
    }
  }
</style>