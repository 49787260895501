<template>
  <div class="centered-wrapper">
    <div class="hall-of-fame-wrapper">

      <ProjectsWall
          :collection="collection"/>

    </div>
  </div>
</template>

<script>
  import constants from "@/helpers/constants";
  import ProjectsWall from "./ProjectsWall";

  export default {
    name: 'Shortlist',
    components: {
      ProjectsWall
    },
    data(){
      return{
        context: 'bosw',
        collection: []
      }
    },
    async mounted(){
      if(document.querySelector('#app').classList.contains('bosa')){
        this.context = 'bosa'
      }

      this.collection = await this.getCollection();
    },
    computed:{
      base_url(){
        if(this.context === 'bosw')
          return constants.BOSW_SHORTLIST_URL;
        else
          return constants.BOSA_SHORTLIST_URL;
      }
    },
    methods:{
      async getCollection(){
        let collection = [];

        try{
          const shortlistResponse = await fetch(this.base_url, {mode: 'cors'});

          collection = await shortlistResponse.json();
        }
        catch{
          console.log('error');
        }

        return collection;
      }
    }
  };
</script>

<style scoped lang="scss">
  .hall-of-fame-wrapper{
    margin-bottom:$spacer*2;
  }

  .accordion .hall-of-fame-wrapper .component-margin{
    margin-top:0 !important;
  }
</style>