<template>
  <div class="hero-cta-list-wrapper" ref="ctalistcomp">
    <HeroCTAItem ref="ctalist" v-for="(item, index) in ctas"
      :key="index"
      :item="item"
      :class="{active: index === activeIndex, initialized: initialized}"
      @cta-navigate="navigate"/>
  </div>
</template>

<script>
  import HeroCTAItem from "./HeroCTAItem";
  export default {
    name: 'HeroCTAList',
    components: {HeroCTAItem},
    props: ['ctas'],
    data(){
      return{
        activeIndex:0,
        stopLoop: false,
        initialized: false,
        interval: 3000,
        intervalId: ''
      }
    },
    mounted(){
      // Set the hyphenation only on overflowing elements
      for(let item of this.$refs.ctalist){
        item.setHyphenation();
      }

      // Perform the rest of the initialization once the size with the hyphenation is already rendered
      this.$nextTick(function () {
        this.setHeight();
        this.activateSlidingLoop();
        this.setAppearance();
      });
    },
    destroyed(){
      this.deactivateSlidingLoop();
    },
    methods:{
      activateSlidingLoop(){
        this.initialized = true;
        this.intervalId = setInterval(this.automaticSlide, this.interval)
      },
      deactivateSlidingLoop(){
        clearInterval(this.intervalId);
      },
      automaticSlide(){
        if(!this.stopLoop){
          this.goToNext();
        }
      },
      goToNext(){
        if(this.activeIndex + 1 >= this.ctas.length){
          this.activeIndex = 0;
        }
        else{
          this.activeIndex = this.activeIndex+1;
        }
        this.setAppearance();
      },
      goToPrevious(){
        if(this.activeIndex - 1 >= 0){
          this.activeIndex = this.activeIndex-1;
        }
        else{
          this.activeIndex = this.ctas.length-1;
        }
        this.setAppearance();
      },
      setAppearance(){
        // Active CTA has automatic width and full opacity
        this.$refs.ctalist[this.activeIndex].$el.style.width = '';
        this.$refs.ctalist[this.activeIndex].$el.style.opacity = '1';

        let width = this.ctas.length-1;

        // Previous CTAs progressively narrower and translucent
        if(this.activeIndex > 0){
          let previous = this.activeIndex; //N. of previous CTAs

          // index used to represent distance from the active CTA
          // i used to represent the CTA's position inside the array
          let index = 0;

          for (let i=previous-1; i >=0; i--){
            this.$refs.ctalist[i].$el.style.width = width-index + 'px';
            this.$refs.ctalist[i].$el.style.opacity = (0.8-(index/10)*2).toString();
            index++;
          }
        }

        // Following CTAs progressively narrower and translucent
        if(this.activeIndex < this.ctas.length-1) {
          let next = this.ctas.length - this.activeIndex - 1; //N. of next CTAs

          // index used to represent distance from the active CTA
          // i used to represent the CTA's position inside the array
          // In this case index is redundant but the same implementation is kept for consistency
          let index = 0;

          for (let i=0; i < next; i++){
            this.$refs.ctalist[this.activeIndex+i+1].$el.style.width = width-index + 'px';
            this.$refs.ctalist[this.activeIndex+i+1].$el.style.opacity = (0.8-(index/10)*2).toString();
            index++;
          }
        }
      },
      setHeight(){
        // Set the component height when all the CTAs are open as the general fixed height
        // Basically sets the component's fixed height to the height of the higher CTA
        const height = this.$refs.ctalistcomp.clientHeight;
        this.$refs.ctalistcomp.style.height = height+'px';
      },
      navigate(direction){
        if(direction === 'next' && this.activeIndex+1 < this.ctas.length){
          this.stopLoop = true;
          this.goToNext();
        }
        else if(direction === 'previous' && this.activeIndex-1 >= 0){
          this.stopLoop = true;
          this.goToPrevious();
        }
      }
    }
  };
</script>
<style scoped lang="scss">
  .hero-cta-list-wrapper{
    display:flex;
    justify-content: center;
    position:absolute;
    bottom:$spacer*2;
    width:auto;
    min-width:100%;
  }

  @include media-breakpoint-down(md){
    .hero-cta-list-wrapper{
      bottom:$spacer*1.5;
    }
  }

  @include media-breakpoint-down(sm){
    .hero-cta-list-wrapper{
      bottom:$spacer;
    }
  }
</style>