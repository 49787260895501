<template>
  <b-link class="profile" :href="linkedin" target="_blank">

    <b-img :src="image"></b-img>

    <div class="info-wrapper">

      <div class="info">
        <span v-if="titlePrefix" :title="titlePrefix">
          {{titlePrefix}}
        </span>
        <span
            v-for="(item, index) in title"
            :key="'title' + index"
            :title="computed(item)">
          <a v-if="item === 'email'" :href="'mailto:'+computed(item)">{{computed(item)}}</a>
          <span v-else>{{computed(item)}}</span>

        </span>

        <span class="subtitle" v-if="subtitlePrefix" :title="subtitlePrefix">
          {{subtitlePrefix}}
        </span>
        <span
            class="subtitle"
            :class="{'show-on-hover': subtitleOnMouseOver}"
            v-for="(item, index) in subtitle"
            :key="'subtitle' + index"
            :title="computed(item)">
          <a v-if="item === 'email'" :href="'mailto:'+computed(item)">{{computed(item)}}</a>
          <span v-else>{{computed(item)}}</span>
        </span>
      </div>

      <div v-if="linkedin" class="linkedin-icon">
        <span v-html="linkedinIcon"></span>
      </div>

    </div>

  </b-link>
</template>

<script>
  import { BImg, BLink } from 'bootstrap-vue';
  import { getHost } from "../helpers/AppHelpers";
  import {linkedin} from "../styles/icons";
  export default {
    name: 'ProfilesItem',
    props: ['item', 'title', 'subtitle', 'showSubtitleOnMouseOver'],
    components:{
      'b-img': BImg,
      'b-link': BLink
    },
    data(){
      return {
        linkedinIcon: linkedin
      }
    },
    computed:{
      contact(){
        return this.item.contact;
      },
      salutation(){
        return this.contact.salutation || '';
      },
      name(){
        return `${this.contact.firstName || ''} ${this.contact.lastName || ''}`;
      },
      honorificName(){
        return `${this.contact.salutation || ''} ${this.name}`;
      },
      company(){
        return this.contact.organization || '';
      },
      role(){
        return this.contact.role || '';
      },
      phone(){
        return this.contact.officePhone || '';
      },
      email(){
        return this.contact.email || '';
      },
      linkedin(){
        return this.contact.website || '';
      },
      titlePrefix(){
        return this.item.titlePrefix || '';
      },
      subtitlePrefix(){
        return this.item.subtitlePrefix || '';
      },
      image(){
        if(this.contact.image){
          const image = JSON.parse(this.contact.image);
          return getHost() + image.metadata.renditions.large.link;
        }
        else
          return '';
      },
      subtitleOnMouseOver(){
        return this.showSubtitleOnMouseOver ? JSON.parse(this.showSubtitleOnMouseOver) : false;
      }
    },
    methods:{
      computed(property){
        return this[property];
      }
    }
  };
</script>
<style scoped lang="scss">

  .profile{
    width:rem(250px);
    padding:0 $spacer/2 $spacer*2 $spacer/2;
    border:none;

    img{
      width:100%;
      height:rem(270px);
      object-fit:cover;
      object-position: center;
    }

    .info-wrapper{
      background-color:$black;
      color:$white;
      margin-top:rem(-20px);
      min-height:rem(150px);
      transition: $transition-base;
      position:relative;

      .info{
        display:flex;
        flex-direction:column;
        padding:$spacer $spacer/2;
        padding-bottom:$spacer*1.2;
        font-size:1rem;
        text-transform: uppercase;
        font-style:italic;
        font-weight:400;
        line-height:1.2;

        a:hover{
          text-decoration:underline;
        }

        span{
          overflow: hidden;
          text-overflow: ellipsis;
          hyphens:auto;
        }

        .subtitle{
          font-size:$smaller-font-size*0.8;
          padding-top:$spacer/6;
          transition:$transition-fade;

          &.show-on-hover{
            opacity:0;
          }
        }
      }

      .linkedin-icon{
        position:absolute;
        bottom:$spacer/6;
        left:$spacer/2;
        /deep/ span svg{
          height:1rem;
          width:1rem;
        }
      }
    }

    &:nth-of-type(odd){
      .info-wrapper{
        transform:skewY(9deg);

        .info,
        .linkedin-icon{
          transform:skewY(-9deg);
        }
      }
    }

    &:nth-of-type(even){
        padding-top:$spacer;

        .info-wrapper{
          transform:skewY(-9deg);

          .info,
          .linkedin-icon{
            transform:skewY(9deg);
          }
        }
    }

    &:hover{
      .info-wrapper{
        background-color: var(--accent-color);

        .subtitle{
          &.show-on-hover{
            opacity:1;
          }
        }
      }
    }
  }
  @include media-breakpoint-down(md){
    .profile{
      width:rem(230px);
      padding:0 $spacer/2 $spacer $spacer/2;

      img{
        height:rem(210px);
      }

      .info-wrapper{
        min-height:rem(150px);

        .info{
          padding:$spacer/1.5 $spacer/2;
          padding-bottom:$spacer*1.2;
          font-size:$smaller-font-size;

          .subtitle{
            font-size:$smaller-font-size*0.7;
            padding-top:$spacer/12;
            font-weight:300;
          }
        }

        .linkedin-icon{
          bottom:$spacer/5;
        }
      }
    }
  }

  @include media-breakpoint-down(sm){
    .profile{
      width:rem(200px);

      img{
        height:rem(200px);
      }

      .info-wrapper .linkedin-icon{
        bottom:$spacer/4;
      }
    }
  }
</style>
