<template>
    <b-link
        :href="standardLink"
        :to="internalLink"
        :target="target"
        :download="download"
        @click="handleClick">
      <slot></slot>
    </b-link>
</template>

<script>
  import { BLink } from 'bootstrap-vue';
  import {formatAnchor, getHost, updateViewKey} from "../helpers/AppHelpers";
  export default {
    name: 'AdvancedLink',
    props: ['link'],
    components:{
      'b-link': BLink
    },
    computed:{
      standardLink(){
        if(this.link.type === 'external')
          return this.link.external_link;
        else if(this.link.type === 'download')
          return getHost() + this.link.download_link['@link'];
        else if(this.link.type === 'phone')
          return 'tel:'+ this.link.number;
        else
          return '';
      },
      internalLink(){
        if(this.link.type === 'internal')
          return this.link.internal_link.replace(sessionStorage.basename, '');
        else
          return '';
      },
      target(){
        return this.link.target === 'true' ? '_blank' : '_self';
      },
      download(){
        return this.link.type === 'download' ? this.link.download_link['@name'] : false;
      },
      anchor(){
        return this.link.type === 'anchor' ? '#'+ this.link.anchor : '';
      }
    },
    methods:{
      handleClick(){
        if(this.link.type === 'internal')
          updateViewKey();
        else if(this.link.type === 'anchor'){
          const formattedAnchor = formatAnchor(this.anchor);
          this.$scrollTo(formattedAnchor, 500, { easing: 'ease-in-out', offset: -100 });
        }
      }
    }
  };
</script>