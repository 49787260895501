export default {
    inserted: el => {
        function loadImage() {
            const imageElement = Array.from(el.children).find(
                el => {
                    let elem = null

                    if(el.nodeName === "IMG") elem = el
                    if(el.nodeName === "DIV") {
                        for (let item of el.classList){
                            if(item === "background-image") {
                                elem = true
                            }
                        }
                    }
                    if(elem!== null) {
                        return true
                    }
                }
            );
            if (imageElement) {
                imageElement.addEventListener("load", () => {
                    setTimeout(() => {
                        for(let child of el.children){
                            child.classList.add("loaded")
                        }
                    }, 100);
                });
                imageElement.addEventListener("error", () => console.log("error"));
                if (imageElement.nodeName === "IMG")  imageElement.src = imageElement.dataset.url
                else {
                    const imageUrl = imageElement.dataset.background;
                    let preloaderImg = document.createElement("img");
                    preloaderImg.src = imageUrl;

                    let w = window.innerWidth;
                    if(w > 768) {
                        imageElement.style.backgroundImage = "url('" +imageElement.dataset.background + "')";

                        preloaderImg.addEventListener('load', () => {
                            setTimeout(() => {
                                el.children[0].classList.add("loaded")
                                preloaderImg = null;
                            }, 100);

                        });
                    } else {
                        imageElement.style.removeProperty('background-image');
                    }
                }
            }
        }

        function handleIntersect(entries, observer) {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    loadImage();
                    observer.unobserve(el);
                }
            });
        }

        function createObserver() {
            const options = {
                root: null,
                threshold: "0"
            };
            const observer = new IntersectionObserver(handleIntersect, options);
            observer.observe(el);
        }
        if (window["IntersectionObserver"]) {
            createObserver();
        } else {
            loadImage();
        }

        function reportWindowResize() {
            if (window["IntersectionObserver"]) {
                createObserver();
            } else {
                loadImage();
            }
        }

        window.addEventListener('resize', reportWindowResize);
    }
};