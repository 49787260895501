<template>
    <div no-body class="accordion">
      <h2 header-tag="header" role="tab">
        <div v-b-toggle="id" name="accordion">
            <span class="toggle-icons">
              <span class="icon open" v-html="iconClose"></span>
              <span class="icon closed" v-html="iconArrowDown"></span>
            </span>
          <div class="accordion-title">
            <strong>{{ project.project.trim() }}</strong>
          </div>
        </div>
      </h2>
      <b-collapse class="accordion-content" :id="id" role="tabpanel" @show="checkIfPortrait">
        <ProjectDetails :project="project" :open="true" ref="projectdetails"></ProjectDetails>
      </b-collapse>
    </div>
</template>

<script>
  import ProjectDetails from "./ProjectDetails";
  import { iconArrowDown, iconClose} from "../../styles/icons";

  export default {
    name: 'ProjectsWallItem',
    components: {
      ProjectDetails,
    },
    props: ['project', 'index'],
    data(){
      return{
        context: 'bosw',
        id: 'masternominationen_' + Math.floor(Math.random() * 1000) + '_' + this.index,
        iconArrowDown: iconArrowDown,
        iconClose: iconClose
      }
    },
    mounted(){
      if(document.querySelector('#app').classList.contains('bosa')){
        this.context = 'bosa'
      }
    },
    computed:{
    },
    methods:{
      checkIfPortrait(){
        this.$refs.projectdetails.checkIfPortrait();
      }
    }
  };
</script>

<style scoped lang="scss">
  .accordion {
    margin: auto;
    margin-bottom: $spacer/1.5;
    width: 100%;
    text-align: center;

    .accordion-title {
      display: inline-block;
      font-style: italic;
    }

    h2 {
      text-transform: uppercase;

      & > div {
        display: flex;
        flex-direction: row;
        flex-shrink: 1;
      }
    }

    .toggle-icons {
      display: inline-block;
      padding-right:$spacer;
      font-weight: bold;

      /deep/ svg{
        height: rem(36px);
        width: rem(36px);
        margin-top: rem(-5px);
      }
    }

    .accordion-content {
      display: block;
      margin-left: $spacer + rem(36px);
      padding-bottom: $spacer;
    }

    div {
      text-align: left;
    }

    .collapsed {
      .open {
        display: none;
      }
    }

    .not-collapsed {
      .closed {
        display: none;
      }
    }
  }

  @include media-breakpoint-down(md) {

    .accordion {
      .accordion-content {
        margin-left: $spacer/2 + rem(28px);
      }

      .toggle-icons {
        padding-right: $spacer/2;

        /deep/ svg {
          height: rem(28px);
          width: rem(28px);
          margin-top: rem(-5px);
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {

    h2 {
      margin-bottom: $spacer/4;
    }

    .accordion {
      .accordion-content {
        margin-left: $spacer/2 + rem(22px);
      }

      .toggle-icons {
        /deep/ svg {
          height: rem(22px);
          width: rem(22px);
        }
      }
    }
  }
</style>