<template>
  <div>
    <b-navbar type="light" variant="white">
        <b-navbar-nav :class="{'add-padding': topNavigation.length > 3}">
          <div class="partners">
            <NavigationItem
                v-for="item in topNavigation"
                :key="item['@id']"
                :item="item"
                :isHomePage="isHomePage"/>
          </div>

          <div class="right">
            <LanguageSelector/>

            <b-navbar-toggle target="hamburger-menu" class="hamburger hamburger--squeeze" id="hamburgerToggleButton">
              <template v-slot:default="{ expanded }">
                  <span class="hamburger-box">
                    <span class="hamburger-inner"></span>
                  </span>
              </template>
            </b-navbar-toggle>
          </div>

        </b-navbar-nav>
    </b-navbar>
    <HamburgerMenu
        :hamburgerNavigation="hamburgerNavigation"
        :isHomePage="isHomePage"/>
  </div>
</template>

<script>
import HamburgerMenu from './HamburgerMenu';
import NavigationItem from './NavigationItem';
import LanguageSelector from "./LanguageSelector";

export default {
  name: 'Navigation',
  props:['topNavigation', 'hamburgerNavigation', 'detailMode', 'isHomePage'],
  components: {
    LanguageSelector,
    HamburgerMenu,
    NavigationItem
  }
};
</script>
<style lang="scss" scoped>
  .right{
    display:flex;
    position:absolute;
    right: $spacer/2;
  }

  .navbar{
    padding:$spacer/2;
  }

  .navbar-expand .navbar-nav{
    width:100%;
    justify-content: center;

    .partners{
      display:flex;
    }
  }

  @include media-breakpoint-down(md){
    .detail-mode{
      display:none !important;
    }
  }

  @include media-breakpoint-down(sm){
    .navbar{
      padding:$spacer/3;
    }

    .right{
      right: $spacer/3;
    }

    .partners{
      max-width:100%;
    }

    .add-padding{
      padding-right:rem(100px);
      justify-content: flex-end !important;
    }
  }
</style>
