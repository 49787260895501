<template>
  <li class="nav-item">
    <router-link
        class="nav-link"
        :to="destination"
        @click.native="updateViewKey">
      <span>{{ item.title }}</span>
    </router-link>
  </li>
</template>

<script>
import {updateViewKey} from "../helpers/AppHelpers";

export default {
  name: 'HamburgerMenuItem',
  props:['item'],
  computed:{
    destination(){
      return this.item['@path'].replace(sessionStorage.basename, '');
    }
  },
  methods:{
    updateViewKey: function(){
      updateViewKey();
    }
  }
};
</script>
<style lang="scss" scoped>
  .nav-item{
    text-align:right;

    .nav-link{
      border:0;
      text-transform: uppercase;
      font-weight:500;
      font-style:italic;
      font-size:1.5rem;
      padding-right:$spacer/2;
    }
  }
</style>