<template>
  <div class="hero-wrapper">
    <video :src="videoSrc" autoplay muted loop></video>
    <div class="scroll" @click="scrollTo">
      <b-img :src="scrollIcon"></b-img>
    </div>
    <HeroCTAList :ctas="ctas"/>
  </div>
</template>

<script>
  import { BImg } from 'bootstrap-vue';
  import HeroCTAList from "./HeroCTAList";
  import {formatAnchor, getHost} from "../helpers/AppHelpers";
  export default {
    name: 'Hero',
    components: {
      HeroCTAList,
      'b-img': BImg
    },
    props: ['video','anchor', 'ctas'],
    data(){
      return{
        scrollIcon: `${process.env.VUE_APP_HOST}${process.env.VUE_APP_IMAGES_PATH}scroll.png`
      }
    },
    computed:{
      videoSrc(){
        return getHost() + this.video['@link'];
      }
    },
    methods:{
      scrollTo(){
        const formattedAnchor = formatAnchor(this.anchor);
        this.$scrollTo('#'+formattedAnchor, 500, { easing: 'ease-in-out', offset: -100 });
      }
    }
  };
</script>
<style scoped lang="scss">
  .hero-wrapper{
    height:auto;
    max-height:rem(680px);
    position:relative;
    overflow:hidden;

    video{
      width:100%;
    }

    .scroll{
      text-transform: uppercase;
      position:absolute;
      top:$spacer*3;
      width:100%;
      display:flex;
      justify-content:center;
      cursor:pointer;

      img{
        height:rem(60px);
      }
    }
  }

  @include media-breakpoint-down(md){
    .hero-wrapper{
      .scroll{
        top:$spacer*2;
      }
    }
  }

  @include media-breakpoint-down(sm){
    .hero-wrapper{
      height:350px;

      video{
        height:100%;
        object-fit:cover;
      }

      .scroll{
        top:$spacer*2;

        img{
          height:rem(50px);
        }
      }
    }
  }
</style>