<template>
  <div class="centered-wrapper">
    <div class="hall-of-fame-wrapper component-margin">
      <h1 v-if="title" class="headline text-center paddingBottom">{{ title }}</h1>
      <div class="filter">
        <b-form-select v-model="selected_year" :options="years" :title="selected_year"></b-form-select>
        <b-form-select v-model="selected_category" :options="categories" :title="selected_category" :disabled="isSingleCategory"></b-form-select>
        <b-form-select v-model="selected_award" :options="availableAwards" :title="selected_award"></b-form-select>
      </div>

      <ProjectsWall
          :collection="selected_collection" :key="selected_collection_key"/>

    </div>
  </div>
</template>

<script>
  import constants from "@/helpers/constants";
  import { BFormSelect } from 'bootstrap-vue';
  import ProjectsWall from "./ProjectsWall";

  export default {
    name: 'HallOfFame',
    components: {
      ProjectsWall,
      'b-form-select': BFormSelect
    },
    props: ['fromYear', 'toYear', 'title', 'category'],
    data(){
      return{
        context: 'bosw',
        collection: [],
        years: [{value: 'all', text: this.$t('filter.all_years')}],
        categories: [{value: 'all', text: this.$t('filter.all_categories')}],
        allAwardTypes: [
          { value: 'all', text: this.$t('filter.all_awards') },
          { value: 'gold', text: this.$t('filter.gold') },
          { value: 'silber', text: this.$t('filter.silber') },
          { value: 'bronze', text: this.$t('filter.bronze') },
          { value: 'shortlist', text: this.$t('filter.shortlist') },
          { value: 'master', text: this.$t('filter.master') }
        ],
        availableAwards: [{ value: 'all', text: this.$t('filter.all_awards') }],
        selected_year: 'all',
        selected_category: 'all',
        selected_award: 'all',
        selected_collection_key: 0
      }
    },
    async mounted(){
      if(document.querySelector('#app').classList.contains('bosa')){
        this.context = 'bosa'
      }

      this.collection = await this.getCollection();

      // If category is provided, filter the collection
      if (this.category) {
        this.categories.push({value: this.category, text: this.category});
        this.selected_category = this.category;
      }

      this.populateDropdowns();
    },
    computed:{
      base_url(){
        if(this.context === 'bosw')
          return constants.BOSW_HOF_URL;
        else
          return constants.BOSA_HOF_URL;
      },
      selected_collection(){
        let collection = [];

        for(let item of this.collection){
          if((this.selected_year === item.year || this.selected_year === 'all')
            && (this.selected_category === item.category || this.selected_category === 'all')
            && (this.selected_award === item.award.toLowerCase() || this.selected_award === 'all')
            && !collection.some(entry => entry.project === item.project && entry.client === item.client && entry.contractor === item.contractor))
          {
            collection.push(item);
          }
        }

        return collection;
      },
      isSingleCategory() {
        return this.category;
      }
    },
    watch:{
      selected_collection(){
        this.selected_collection_key++;
      }
    },
    methods:{
      async getCollection(){
        const start = this.fromYear ? parseInt(this.fromYear) : 2012;
        const end = this.toYear ? parseInt(this.toYear) : new Date().getFullYear();

        let collection = [];

        if(start && end){
          for(let year = start; year <= end; year++){
            try{
              const yearResponse = await fetch(`${this.base_url}hall-of-fame-${year}.json`, {mode: 'cors'});

              let yearEntries = await yearResponse.json();

              if(yearEntries){
                collection = collection.concat(yearEntries);
              }
            } catch {
              console.log('error');
            }
          }
        }

        return collection;
      },
      populateDropdowns() {
        const yearsSet = new Set();
        const categoriesSet = new Set();
        const awardsSet = new Set();

        this.collection.forEach(item => {
          if (this.isSingleCategory) {
            if (item.category === this.category) {
              yearsSet.add(item.year);
              awardsSet.add(item.award.toLowerCase());
            }
          } else {
            yearsSet.add(item.year);
            categoriesSet.add(item.category);
            awardsSet.add(item.award.toLowerCase());
          }
        });

        // Sort years in descending order
        const sortedYears = Array.from(yearsSet).sort((a, b) => b - a);

        this.years = [{ value: 'all', text: this.$t('filter.all_years') }];
        sortedYears.forEach(year => {
          this.years.push({ value: year, text: year });
        });

        if (!this.isSingleCategory) {
          this.categories = [{ value: 'all', text: this.$t('filter.all_categories') }];
          categoriesSet.forEach(category => {
            this.categories.push({ value: category, text: category });
          });
        }

        this.availableAwards = [{ value: 'all', text: this.$t('filter.all_awards') }];
        this.allAwardTypes.forEach(award => {
          if (awardsSet.has(award.value)) {
            this.availableAwards.push(award);
          }
        });

        if (this.years.length > 1) {
          this.selected_year = this.years[1].value;
        }
      }
    }
  };
</script>

<style scoped lang="scss">
  .accordion .hall-of-fame-wrapper.component-margin{
    margin-top:0 !important;
  }

  .hall-of-fame-wrapper{
    margin-bottom:$spacer*2;
  }

 .filter{
   max-width:100%;
   display:flex;
   flex-wrap:wrap;
   justify-content: center;

   select{
     margin: $spacer/4;
     max-width:350px;
     overflow:hidden;
     text-overflow: ellipsis;
     flex-shrink: 1;

     /deep/ option{
       font-weight:300;
     }
   }
 }

 @include media-breakpoint-down(md) {
   .filter{
     select{
       margin:$spacer/6;
       max-width:250px;
     }
   }
 }
</style>