<template>
  <div class="centered-wrapper">
    <div class="accordion accordion-container" role="tablist">
      <MasternominationenItem
        v-for="(project, index) in sortedCollection" :key="index"
        :project="project"
        :index="index"/>
    </div>
  </div>
</template>

<script>
  import constants from "@/helpers/constants";
  import MasternominationenItem from "./MasternominationenItem";

  export default {
    name: 'Masternominationen',
    components: {
      MasternominationenItem
    },
    data(){
      return{
        context: 'bosw',
        collection: []
      }
    },
    async mounted(){
      if(document.querySelector('#app').classList.contains('bosa')){
        this.context = 'bosa'
      }

      this.collection = await this.getCollection();
    },
    computed:{
      base_url(){
        if(this.context === 'bosw')
          return constants.BOSW_MASTERNOM_URL;
        else
          return constants.BOSA_MASTERNOM_URL;
      },
      sortedCollection(){
        // Sort collection by project name
        let sortedCollection = [...this.collection];
        return sortedCollection.sort(function(a, b) {
          const nameA = a.project.toLowerCase();
          const nameB = b.project.toLowerCase();

          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
      }
    },
    methods:{
      async getCollection(){
        let collection = [];

        try{
          const masternomResponse = await fetch(this.base_url, {mode: 'cors'});

          collection = await masternomResponse.json();
        }
        catch{
          console.log('error');
        }

        return collection;
      }
    }
  };
</script>

<style scoped lang="scss">

  .accordion-container {
    width:100%;
    max-width:1150px;
    padding:$spacer;
    padding-top:0;
  }

  @include media-breakpoint-down(md){
    .accordion-container {
      & > div {
        margin: auto;
        margin-bottom: $spacer/2;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .accordion-container {
      & > div {
        margin-bottom: $spacer/4;
      }
    }
  }
</style>