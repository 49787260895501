<template>
  <section class="centered-wrapper" :class="{'component-margin': addTopMargin}">
    <div class="page-content">

      <div no-body class="accordion">

        <div class="centered-wrapper">
          <div class="noselect section-title"
            role="tab"
            v-on:click="setPageContentHash(processedHash)"
            v-b-toggle="'accordion-' + pageContent['@id']"
            name="page-content"
            ref="headline"
            :id="'headline_' + pageContent['@id']">

            <AdvancedLink :link="headlineLink" v-if="headlineLink" class="no-border">
              <Headline :headline="headline" :type="headlineType" :hasMargin="false"></Headline>
            </AdvancedLink>
            <Headline v-else :headline="headline" :type="headlineType" :hasMargin="false"></Headline>

            <div v-if="hasContent()" class="toggle-icons">
              <span class="icon open" v-html="iconClose"></span>
              <span class="icon closed" v-html="iconArrowDown"></span>
            </div>
          </div>

        </div>

        <b-collapse
            :visible="getVisibilityFromHash(processedHash)"
            :id="'accordion-' + pageContent['@id']"
            ref="accordion"
            role="tabpanel" class="content">
          <EditableArea v-if="pageContent" v-bind:content="pageContent" />
        </b-collapse>

      </div>
    </div>

  </section>
</template>

<script>
import { EditableArea, inEditorEdit } from '../vue-editor';
import { iconArrowDown, iconClose} from "../styles/icons";
import Headline from "./Headline";
import AdvancedLink from "./AdvancedLink";

export default {
  name: 'PageContent',
  props: ['headline', 'topMargin','pageContent', 'headlineLink', 'hash', 'accentBackground'],
  components: {
    Headline,
    EditableArea,
    AdvancedLink
  },
  data() {
    return {
      iconArrowDown: iconArrowDown,
      iconClose: iconClose
    }
  },
  computed:{
    addTopMargin(){
      return this.topMargin ? JSON.parse(this.topMargin) : false;
    },
    processedHash(){
      return this.hash ? this.hash.replace(' ', '-') : '';
    },
    isAccentBackground(){
      return this.accentBackground ? JSON.parse(this.accentBackground) : false;
    },
    headlineType(){
      let type = 'inverse shrinked';
      if(this.isAccentBackground)
        type = type + ' accent';
      return type;
    }
  },
  mounted() {
    if (window.location.hash.length > 2 && window.location.hash.slice(1) === this.processedHash) {
      const elementId = '#headline_' + this.pageContent['@id'];
      this.$scrollTo(elementId, 500, { easing: 'ease-in-out', offset: -100 });
    }
  },
  methods: {
    hasContent() {
      return this.pageContent['@nodes'].length;
    },
    setPageContentHash(id) {
      if (!inEditorEdit && id) {
        if (!this.$refs.accordion.$el.classList.contains("show")) {
          history.replaceState(null, null, window.location.pathname + '#' + id);
        }
        else if(window.location.hash.slice(1) === this.processedHash){
          history.replaceState(null, null, window.location.pathname);
        }
      }
    },
    getVisibilityFromHash(id) {
      if (inEditorEdit) return true;
      return (window.location.hash === '#' + id);
    }
  }
};
</script>

<style scoped lang="scss">
  .page-content {
    width:100%;
    max-width:1150px;
    padding:0 $spacer;

    .section-title{
      margin-bottom:$spacer/2;
      display:flex;
      justify-content: center;
      cursor:pointer;

      &:focus-visible{
        outline:none;
        box-shadow: $focus-shadow;
      }

      /deep/ .headline{
        margin-bottom:0 !important;
        font-weight:200 !important;
        padding: $spacer/6 $spacer/2 $spacer/8 $spacer/2;
        border:none;
      }

      .toggle-icons {
        padding-left:$spacer/4;
        display:flex;
        align-items: center;

        /deep/ svg{
          height: rem(28px);
          width: rem(28px);
        }
      }
    }

    .content{
      padding-top:$spacer*2;
    }
  }

  .collapsed {
    .open {
      display: none;
    }
  }

  .not-collapsed {
    .closed {
      display: none;
    }
  }

  @include media-breakpoint-down(md){
    .page-content {
      padding:0 $spacer/2;

      .section-title{
        margin-bottom:$spacer/2;

        .toggle-icons {

          /deep/ svg{
            height: rem(20px);
            width: rem(20px);
          }
        }
      }

      .content{
        padding-top:$spacer/2;
      }
    }
  }

  @include media-breakpoint-down(sm){
    .page-content {

      .section-title{
        margin-bottom:$spacer/2;

        .toggle-icons {

          /deep/ svg{
            height: rem(20px);
            width: rem(20px);
          }
        }
      }

      .content{
        padding-top:$spacer/2;
      }
    }
  }
</style>
