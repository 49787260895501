<template>
  <div class="centered-wrapper">
    <div class="centered-wrapper profiles-wrapper">
      <ProfilesItem
          v-for="(item, index) in contacts"
          :key="index"
          :item="item"
          :title="title"
          :subtitle="subtitle"
          :showSubtitleOnMouseOver="showSubtitleOnMouseover"/>
    </div>
  </div>
</template>

<script>
  import ProfilesItem from "./ProfilesItem";
  export default {
    name: 'Profiles',
    components: {ProfilesItem},
    props: [
      'contacts',
      'nameCompositeTitle',
      'showPhoneTitle',
      'showEmailTitle',
      'showCompanyTitle',
      'showRoleTitle',
      'nameCompositeSubtitle',
      'showPhoneSubtitle',
      'showEmailSubtitle',
      'showCompanySubtitle',
      'showRoleSubtitle',
      'showSubtitleOnMouseover'
    ],
    computed:{
      title(){
        let title = [];

        if(this.nameCompositeTitle && this.nameCompositeTitle.showNameTitle && JSON.parse(this.nameCompositeTitle.showNameTitle)){
          if(this.nameCompositeTitle.showHonorificTitle && JSON.parse(this.nameCompositeTitle.showHonorificTitle))
            title.push('honorificName');
          else
            title.push('name');
        }

        if(this.showPhoneTitle && JSON.parse(this.showPhoneTitle))
          title.push('phone');

        if(this.showRoleTitle && JSON.parse(this.showRoleTitle))
          title.push('role');

        if(this.showEmailTitle && JSON.parse(this.showEmailTitle))
          title.push('email');

        if(this.showCompanyTitle && JSON.parse(this.showCompanyTitle))
          title.push('company');

        return title;
      },
      subtitle(){
        let subtitle = [];

        if(this.nameCompositeSubtitle && this.nameCompositeSubtitle.showNameSubtitle && JSON.parse(this.nameCompositeSubtitle.showNameSubtitle)){
          if(this.nameCompositeSubtitle.showHonorificSubtitle && JSON.parse(this.nameCompositeSubtitle.showHonorificSubtitle))
            subtitle.push('honorificName');
          else
            subtitle.push('name');
        }

        if(this.showPhoneSubtitle && JSON.parse(this.showPhoneSubtitle))
          subtitle.push('phone');

        if(this.showRoleSubtitle && JSON.parse(this.showRoleSubtitle))
          subtitle.push('role');

        if(this.showEmailSubtitle && JSON.parse(this.showEmailSubtitle))
          subtitle.push('email');

        if(this.showCompanySubtitle && JSON.parse(this.showCompanySubtitle))
          subtitle.push('company');

        return subtitle;
      }
    }
  };
</script>
<style scoped lang="scss">

  .profiles-wrapper{
    max-width:900px;
    flex-wrap:wrap;
    margin:0 -$spacer/2;
    padding-bottom:$spacer;
    padding-top:$spacer;
  }

  @include media-breakpoint-down(md){
    .profiles-wrapper{
      padding-left:$spacer;
      padding-right:$spacer;
    }
  }

  @include media-breakpoint-down(sm){
    .profiles-wrapper{
      padding-left:$spacer/2;
      padding-right:$spacer/2;
    }
  }
</style>