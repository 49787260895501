<template>
  <div>
    <CoolLightBox
        :items="items"
        :index="index"
        @close="closeImage">
    </CoolLightBox>
    <div v-lazyload class="images-wrapper">
      <b-image
          class="image lazy-img-fadein"
          v-for="(image, imageIndex) in items"
          :key="imageIndex"
          @click="index = imageIndex"
          :data-url="image.src"
          :src="image.src"
          :srcset="image.srcset"
          :sizes="image.sizes"
          :alt="caption"
          fluid
      />
    </div>

  </div>

</template>

<script>
  import CoolLightBox from 'vue-cool-lightbox'
  import { BImg} from 'bootstrap-vue'
  import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
  import {getLazyLoadImage} from "../helpers/AppHelpers";
  export default {
    name: 'Image',
    props: ['image', 'caption'],
    components: {
      CoolLightBox,
      'b-image': BImg
    },
    data: function () {
      return {
        items: [],
        index: null,
      }
    },
    methods: {
      closeImage() {
        this.index = null;
      }
    },
    mounted: async function() {
      let image = getLazyLoadImage(this.image);

      this.items.push({title: this.caption, src: image.src, srcset: image.srcset, sizes: image.sizes});
    }

  };
</script>

<style lang="scss" scoped>
  img {
    opacity: 0;
  }

  @keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
  }

  /* Firefox < 16 */
  @-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
  }

  /* Safari, Chrome and Opera > 12.1 */
  @-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
  }

  /* Internet Explorer */
  @-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
  }

  /* Opera < 12.1 */
  @-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
  }
  img[lazy=error] {
    /*your style here*/
  }
  img.loaded {
    opacity: 1;
    animation: fadein 1s ease-in;
  }

  @include media-breakpoint-down(md) {
    .images-wrapper{
      margin-right:-$spacer/2;
      margin-left:-$spacer/2;
    }
  }
</style>
<style>
  .images-wrapper .image {
    cursor: pointer;
    max-width: 100%;
    height: auto;
  }
</style>
